import React from "react";
import { Box, styled } from "@mui/system";
import { Grid, Link, Typography } from "@mui/material";
import theme, { DCButton } from "../../assets/theme/theme";
// import UploadIcon from "../../assets/images/icons/upload_cloud.svg";
import { useDropzoneContext } from "../../contexts/DropzoneContext";
import { useSidebarContext } from "../../contexts/SidebarContext";
import UploadIcon from "../Shared/Icons/UploadIcon";
const Input = styled("input")({
  display: "none",
});
export interface Props {
  show?: boolean;
}

const FooterNotice: React.FC<Props> = (props) => {
  const { open, getInputProps } = useDropzoneContext();
  const { open: sidebarOpen } = useSidebarContext();
  return (
    <Box
      className="drag-document-main"
      position="fixed"
      sx={{
        '@media (max-width:1042px)': {
         height:'auto',
         padding:'12px 15px'
        },
        background: theme.palette.primary.light,
        width: sidebarOpen ? "calc(100% - 240px)" : "100%",
        right: "50%",
        bottom: 0,
        height: 67,
        zIndex: 1,
        gap:'2',
        alignItems:'flexStart',
        transform: sidebarOpen
          ? "translate(calc(50% + 120px),  0)"
          : "translate(50%,  0)",
          
      }}
      pl={2}
      pr={4}
      textAlign="center"
      display="flex"
      alignItems="center"
    >
      {props.show ? (
        <Grid container alignItems="center" justifyContent="space-between" className="drag-document-section"
        sx={{
          '@media (max-width:991x)': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent:'flex-start'
          },}}>
          <Grid  container item alignItems="center" flex={1} className="drag-document-inner"
           sx={{
            '@media (max-width:991px)': {
              display: 'flex',
              flexDirection: 'row',
              justifyContent:'flex-start',
              gap:'8px'
            },}}>
            <UploadIcon />

            <Typography className="drag-doc-inner-container" component="p" mx={2}>
              Drag documents anywhere on the screen to upload or
            </Typography>
            <label htmlFor="contained-button-file">
              <Input {...getInputProps()} />
              <DCButton
                variant="contained"
                onClick={() => {
                  open();
                }}
              >
                Browse
              </DCButton>
            </label>
          </Grid>
          <Grid item textAlign="right" flex={1}>
            <Typography className="maximum-dic-inner" component="p">
              Maximum Document Size should be 20 MB. Only PDF, PNG or JPEG
              Formats are acceptable.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", height: "100%", marginY: "auto"}}>
        <Typography className="dashboard-footer" component="p">
          You haven’t uploaded any document yet. At least upload your <b>passport</b>{" "}
          to{" "}
          <Link color={theme.palette.text.primary} href="/">
            go to dashboard
          </Link>
        </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FooterNotice;
