import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { IDocumentField, ITrackingDates } from "../../firebaseTypes";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandMoreSharp";
import ExpandCircleUpIcon from "@mui/icons-material/ExpandLessTwoTone";
import { get, filter, isNull, isEmpty } from "lodash";
import { useCustomCardModalContext } from "../../contexts/CustomCardModalContext";
import { dateTimeToSeconds, toDateTime } from "../../utils/dateTimeUtils";
import constants from "../../constants";
import { isFuture, sub } from "date-fns";
import theme, { DcDocumentIconButton, DcInput } from "../../assets/theme/theme";
import FormatColorTextOutlinedIcon from "@mui/icons-material/FormatColorTextOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import TimesIcon from "../Shared/Icons/TimesIcon";
import { DCAddressAutocomplete } from "../Shared/DCAddressAutocomplete";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { DCFormLabel } from "../Shared/DCFormLabel";

export interface Props {
  field: IDocumentField;
  index: number;
}

type IValueType = "date" | "text" | "address";

const CustomCardField: React.FC<Props> = ({ field, index }) => {
  const [valueType, setValueType] = React.useState<IValueType>("text");

  const [isExpandTrackDate, setisExpandTrackDate] =
    React.useState<boolean>(false); //For expanding the trackDate in Edit Modal
  const [openPicker, setOpenPicker] = React.useState<boolean>(false);
  const {
    setCustomDocumentEditable,
    setCustomCardField,
    customDocumentEditable,
  } = useCustomCardModalContext();

  const maxLimit = constants.inputText.maxInputLimit;
  const maxLimitMsg = `Max Characters: ${maxLimit}`;
  const [isCrossedMaxLimit, setIsCrossedMaxLimit] =
    React.useState<boolean>(false);

  //Menu Logic
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const parsedDate = field.value?.seconds
    ? toDateTime(field.value?.seconds)
    : null;

  React.useEffect(() => {
    if (field.date === true) {
      setValueType("date");
    } else if (field.address === true) {
      setValueType("address");
    } else {
      setValueType("text");
    }
  }, [field.value.seconds, field.date, field.address]);

  const updateTrackingDates = React.useCallback(
    (seconds) => {
      if (seconds) {
        const parsedDateinMillisec = seconds * 1000;
        const dateIn8m = new Date(sub(parsedDateinMillisec, { months: 8 }));
        const dateIn4m = new Date(sub(parsedDateinMillisec, { months: 4 }));
        const dateIn2m = new Date(sub(parsedDateinMillisec, { months: 2 }));
        const dateIn1m = new Date(sub(parsedDateinMillisec, { months: 1 }));
        const dateIn1d = new Date(sub(parsedDateinMillisec, { days: 1 }));

        //setting the appropriate date value in the state
        setCustomCardField(index, {
          trackingDates: {
            Checkbox1d: {
              value: [dateIn1d, "1D"],
              checked: false,
            },
            Checkbox1m: {
              value: [dateIn1m, "1M"],
              checked: false,
            },
            Checkbox2m: {
              value: [dateIn2m, "2M"],
              checked: false,
            },
            Checkbox4m: {
              value: [dateIn4m, "4M"],
              checked: false,
            },
            Checkbox8m: {
              value: [dateIn8m, "8M"],
              checked: false,
            },
          },
        });
      }
    },
    [setCustomCardField, index]
  );

  const handleChangeType = useCallback(
    (index: number, _type: IValueType) => {
      const emptyDocumentField: Partial<IDocumentField> = {
        date: _type === "date",
        address: _type === "address",
        // empty all values
        zipcode: "",
        apartment: "",
        value: "",
        raw_value: "",
        manual_value: "",
        trackingDates: {
          Checkbox1d: {
            value: [],
            checked: false,
          },
          Checkbox1m: {
            value: [],
            checked: false,
          },
          Checkbox2m: {
            value: [],
            checked: false,
          },
          Checkbox4m: {
            value: [],
            checked: false,
          },
          Checkbox8m: {
            value: [],
            checked: false,
          },
        },
      };
      setCustomCardField(index, emptyDocumentField);
    },
    [setCustomCardField]
  );

  const handleDeleteField = React.useCallback(() => {
    setCustomDocumentEditable((prev) => {
      const oldDocInfo = prev.doc_info || [];
      const newDocInfo = filter(oldDocInfo, (info, i) => i !== index);
      return {
        ...prev,
        doc_info: newDocInfo,
      };
    });
  }, [index, setCustomDocumentEditable]);

  const isDeleteDisabled = !!(
    get(customDocumentEditable, ["doc_info", "length"], 0) === 1
  );

  const switchToDate = React.useCallback(() => {
    handleMenuClose();
    setValueType("date");
    handleChangeType(index, "date");
    setisExpandTrackDate(true);
  }, [handleChangeType, handleMenuClose, index]);

  return (
    <>
      {/* expand-collapse button for date field */}
      {/* {(valueType === 'date' && field.value.seconds !== undefined) && ((isExpandTrackDate) ?
        <Badge color="secondary">
          <ExpandMoreIcon sx={{ marginRight: '10px', marginTop: '5px' }} onClick={() => setisExpandTrackDate(!isExpandTrackDate)} />
        </Badge>
        : <Badge color="secondary">
          <KeyboardArrowUpIcon sx={{ marginRight: '10px', marginTop: '5px' }} onClick={() => setisExpandTrackDate(!isExpandTrackDate)} />
        </Badge>)} */}

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          className="card-form-text"
          sx={{
            marginBottom: "30px",
            bgcolor: theme.palette.background.default,
            borderRadius: 2,
            p: 2,
            pb: 4,
            position: "relative",
          }}
        >
          {valueType === "address" ? (
            <Stack direction="column" spacing={2} className="card-form-values">
              {/* Render Key */}
              <Stack flex={1} display="flex" spacing={2} direction="row">
                <FormControl sx={{ flex: 1 }}>
                  <InputLabel
                    sx={{
                      mb: 1,
                    }}
                  >
                    Title*
                  </InputLabel>
                  <DcInput
                    value={field.title}
                    required={true}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setCustomCardField(index, { title: e.target.value });
                    }}
                    onInput={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      evt.target.value = evt.target.value.slice(0, maxLimit);
                      evt.target.value.length === maxLimit
                        ? setIsCrossedMaxLimit(true)
                        : setIsCrossedMaxLimit(false);
                    }}
                    error={isEmpty(field.title)}
                    fullWidth
                    placeholder="Title"
                    multiline
                  />
                  {isCrossedMaxLimit && (
                    <FormHelperText>{maxLimitMsg}</FormHelperText>
                  )}
                </FormControl>

                {/* input type change button */}
                <Tooltip arrow title="Change Input" placement="top">
                  <DcDocumentIconButton
                    className="type-menu-form-btn"
                    aria-label="select-type"
                    id="type-menu"
                    aria-controls={openMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={handleMenuClick}
                    color="primary"
                    sx={{ top: 25 }}
                  >
                    <PlaceOutlinedIcon fontSize="small" />
                  </DcDocumentIconButton>
                </Tooltip>
                <Menu
                  id="type-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setValueType("text");
                      handleChangeType(index, "text");
                    }}
                  >
                    <ListItemIcon style={{ minWidth: "unset" }}>
                      <FormatColorTextOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Text</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setValueType("address");
                      handleChangeType(index, "address");
                    }}
                  >
                    <ListItemIcon style={{ minWidth: "unset" }}>
                      <PlaceOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Address</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={switchToDate}>
                    <ListItemIcon style={{ minWidth: "unset" }}>
                      <DateRangeOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Date</ListItemText>
                  </MenuItem>
                </Menu>
              </Stack>
              <Stack flex={1} display="flex" spacing={2} direction="column">
                <Box>
                  <DCFormLabel>Value*</DCFormLabel>
                  <DCAddressAutocomplete
                    defaultValue={field.value}
                    inputProps={{
                      required: true,
                      placeholder:
                        "e.g. Apple Park, Apple Park Way, Cupertino, CA, USA",
                      multiline: true,
                      error: isEmpty(field.value),
                      sx: { flexGrow: 1 },
                    }}
                    onChange={(val, addressData) => {
                      setCustomCardField(index, { value: val });
                    }}
                  />
                </Box>
              </Stack>

              <Stack direction="row" spacing={2}>
                <FormControl sx={{ flex: 1 }}>
                  <InputLabel>Apartment</InputLabel>
                  <DcInput
                    defaultValue={field.apartment}
                    placeholder="Apartment"
                    sx={{ flexGrow: 1 }}
                    onChange={({ target: { value: val } }) => {
                      setCustomCardField(index, { apartment: val });
                    }}
                    multiline
                  />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                  <InputLabel>Zipcode</InputLabel>
                  <DcInput
                    defaultValue={field.zipcode}
                    placeholder="Zipcode"
                    sx={{ flexGrow: 1 }}
                    onChange={({ target: { value: val } }) => {
                      setCustomCardField(index, { zipcode: val });
                    }}
                    multiline
                  />
                </FormControl>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" spacing={2} className="field-box"> 
              {/* Render Key */}
              <Box flex={1} className="field-box-title">
                {/* undo-todo: pick validations from piyali's PR */}
                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      mb: 1,
                    }}
                  >
                    Title*
                  </InputLabel>
                  <DcInput
                    value={field.title}
                    required={true}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setCustomCardField(index, { title: e.target.value });
                    }}
                    onInput={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      evt.target.value = evt.target.value.slice(0, maxLimit);
                      evt.target.value.length === maxLimit
                        ? setIsCrossedMaxLimit(true)
                        : setIsCrossedMaxLimit(false);
                    }}
                    error={isEmpty(field.title)}
                    fullWidth
                    placeholder="Title"
                    multiline
                  />
                  {isCrossedMaxLimit && (
                    <FormHelperText>{maxLimitMsg}</FormHelperText>
                  )}
                </FormControl>
              </Box>

              {/* Render text value */}
              {valueType === "text" && (
                <Box flex={1} className="field-type-value">
                  <FormControl fullWidth>
                    <InputLabel
                      sx={{
                        mb: 1,
                      }}
                    >
                      Value*
                    </InputLabel>
                    <DcInput
                      defaultValue={field.value}
                      required={true}
                      placeholder="Value"
                      inputProps={{
                        style: {
                          minHeight: 18,
                        },
                      }}
                      minRows={1}
                      multiline
                      fullWidth
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setCustomCardField(index, { value: e.target.value });
                      }}
                      error={isEmpty(field.value)}
                    />
                  </FormControl>
                </Box>
              )}

              {/* Render date value */}
              {valueType === "date" && (
                <>
                  <Box flex={1} className="field-type-value">
                    <FormControl fullWidth>
                      <DesktopDatePicker
                        open={openPicker}
                        onClose={() => setOpenPicker(false)}
                        value={parsedDate}
                        inputFormat={constants.dateFormat}
                        disableOpenPicker
                        onChange={(newValue) => {
                          if (newValue) {
                            const changes = {
                              value: {
                                seconds: dateTimeToSeconds(newValue as Date),
                              },
                            };
                            setCustomCardField(index, changes);
                            updateTrackingDates(changes.value.seconds);
                          }
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <>
                            <InputLabel
                            className="Date-section"
                              sx={{
                                mb: 1,
                              }}
                            >
                              Date*
                            </InputLabel>
                            {/* @ts-ignore */}
                            <DcInput
                              {...inputProps}
                              ref={inputRef}
                              sx={{ flexGrow: 1 }}
                              fullWidth
                              placeholder="DD/MM/YYYY"
                              error={isNull(parsedDate)}
                              required={true}
                              // helperText={parsedDate ? null : 'Required'}
                              onClick={() => setOpenPicker(true)}
                            />
                            {InputProps?.endAdornment}
                          </>
                        )}
                      />
                    </FormControl>
                  </Box>

                  {/* {isExpandTrackDate ? (
                    <Tooltip arrow title="Expand" placement="top">
                      <DcDocumentIconButton
                        className="expand-section-blog"
                        disabled={isNull(parsedDate)}
                        aria-label="Expand section"
                        onClick={() => setisExpandTrackDate((prev) => !prev)}
                        sx={{ top: 25, ml: 2 }}
                      >
                        <ExpandCircleUpIcon fontSize="small" />
                      </DcDocumentIconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip arrow title="Collapse" placement="top">
                      <DcDocumentIconButton
                        className="collapse-blog"
                        disabled={isNull(parsedDate)}
                        aria-label="collapse-section"
                        onClick={() => setisExpandTrackDate((prev) => !prev)}
                        sx={{ top: 25, ml: 2 }}
                      >
                        <ExpandCircleDownIcon fontSize="small" />
                      </DcDocumentIconButton>
                    </Tooltip>
                  )} */}
                </>
              )}

              <>
                {/* input type change button */}
                <Tooltip arrow title="Change Input" placement="top">
                  <DcDocumentIconButton
                    className="select-type-btn"
                    aria-label="select-type"
                    id="type-menu"
                    aria-controls={openMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={handleMenuClick}
                    color="primary"
                    sx={{ top: 25 }}
                  >
                    {valueType === "text" ? (
                      <FormatColorTextOutlinedIcon fontSize="small" />
                    ) : (
                      <DateRangeOutlinedIcon fontSize="small" />
                    )}
                  </DcDocumentIconButton>
                </Tooltip>
                <Menu
                  id="type-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setValueType("text");
                      handleChangeType(index, "text");
                    }}
                  >
                    <ListItemIcon style={{ minWidth: "unset" }}>
                      <FormatColorTextOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Text</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setValueType("address");
                      handleChangeType(index, "address");
                    }}
                  >
                    <ListItemIcon style={{ minWidth: "unset" }}>
                      <PlaceOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Address</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={switchToDate}>
                    <ListItemIcon style={{ minWidth: "unset" }}>
                      <DateRangeOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Date</ListItemText>
                  </MenuItem>
                </Menu>
              </>
            </Stack>
          )}

          {/* Render date expand section */}
          {isExpandTrackDate && valueType === "date" && field.trackingDates && (
            <TrackingDatesSection
              trackingDates={field.trackingDates}
              fieldIndex={index}
            />
          )}
          {/* Item clear button */}
          <Tooltip arrow title="Delete" placement="top">
            <DcDocumentIconButton
              aria-label="delete parameter"
              onClick={handleDeleteField}
              disabled={isDeleteDisabled}
              sx={{
                position: "absolute",
                right: 0,
                bottom: -14,
                left: 0,
                mx: "auto  !important",
                height: 24,
                width: 24,
                background: theme.palette.primary.light,
                color: theme.palette.text.primary,
                border: `3px solid ${theme.palette.common.white}`,
              }}
            >
              <TimesIcon sx={{ fontSize: 14 }} />
            </DcDocumentIconButton>
          </Tooltip>
        </Box>
      </LocalizationProvider>
    </>
  );
};

export default CustomCardField;

const TrackingDatesSection: React.FC<{
  trackingDates: ITrackingDates;
  fieldIndex: number;
}> = ({ trackingDates, fieldIndex }) => {
  const { setCustomCardField } = useCustomCardModalContext();

  const updateCheckbox = useCallback(
    (
      checkboxKey:
        | "Checkbox8m"
        | "Checkbox4m"
        | "Checkbox2m"
        | "Checkbox1m"
        | "Checkbox1d",
      checked: boolean
    ) => {
      const changes = {
        trackingDates: {
          ...trackingDates,
          [checkboxKey]: {
            ...trackingDates[checkboxKey],
            checked: checked,
          },
        },
      };

      setCustomCardField(fieldIndex, changes as any);
    },
    [trackingDates, setCustomCardField, fieldIndex]
  );

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.common.border}`,
        bgcolor: theme.palette.common.white,
        borderRadius: "5px",
        mt: 2,
        padding: "10px",
        flex: 0,
      }}
    >
      <Typography sx={{ marginRight: "20px" }} variant="subtitle2">
        Track "Date":{" "}
      </Typography>

      <Tooltip
        arrow
        title="You will get notification in 6 Months"
        placement="bottom"
      >
        <span>
          <FormControlLabel
            control={
              <Checkbox
                checked={get(trackingDates, "Checkbox8m.checked")}
                onChange={(e) => {
                  updateCheckbox("Checkbox8m", e.target.checked);
                }}
              />
            }
            label="8 Months"
            disabled={
              !isFuture(
                new Date(get(trackingDates, ["Checkbox8m", "value", 0], 0))
              )
            }
          />
        </span>
      </Tooltip>

      <Tooltip
        arrow
        title="You will get notification in 4 Months"
        placement="bottom"
      >
        <span>
          <FormControlLabel
            control={
              <Checkbox
                checked={get(trackingDates, "Checkbox4m.checked")}
                onChange={(e) => {
                  updateCheckbox("Checkbox4m", e.target.checked);
                }}
              />
            }
            label="4 Months"
            disabled={
              !isFuture(
                new Date(get(trackingDates, ["Checkbox4m", "value", 0], 0))
              )
            }
          />
        </span>
      </Tooltip>

      <Tooltip
        arrow
        title="You will get notification in 2 Months"
        placement="bottom"
      >
        <span>
          <FormControlLabel
            control={
              <Checkbox
                checked={get(trackingDates, "Checkbox2m.checked")}
                onChange={(e) => {
                  updateCheckbox("Checkbox2m", e.target.checked);
                }}
              />
            }
            label="2 Months"
            disabled={
              !isFuture(
                new Date(get(trackingDates, ["Checkbox2m", "value", 0], 0))
              )
            }
          />
        </span>
      </Tooltip>

      <Tooltip
        arrow
        title="You will get notification in 1 Months"
        placement="bottom"
      >
        <span>
          <FormControlLabel
            control={
              <Checkbox
                checked={get(trackingDates, "Checkbox1m.checked")}
                onChange={(e) => {
                  updateCheckbox("Checkbox1m", e.target.checked);
                }}
              />
            }
            label="1 Month"
            disabled={
              !isFuture(
                new Date(get(trackingDates, ["Checkbox1m", "value", 0], 0))
              )
            }
          />
        </span>
      </Tooltip>

      <Tooltip
        arrow
        title="You will get notification in 1 day"
        placement="bottom"
      >
        <span>
          <FormControlLabel
            control={
              <Checkbox
                checked={get(trackingDates, "Checkbox1d.checked")}
                onChange={(e) => {
                  updateCheckbox("Checkbox1d", e.target.checked);
                }}
              />
            }
            label="1 Day"
            disabled={
              !isFuture(
                new Date(get(trackingDates, ["Checkbox1d", "value", 0], 0))
              )
            }
          />
        </span>
      </Tooltip>

      <Typography
        sx={{ display: "block", marginTop: "6px", color: "gray" }}
        variant="caption"
      >
        Tracking this date will send you reminder notification for the specific
        date{" "}
      </Typography>
    </Box>
  );
};
