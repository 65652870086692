import { Box } from "@mui/material";
import { get } from "lodash";
import DocumentViewer from "../Shared/DocumentViewer";
import { usePublicDocumentSharePageContext } from "../../contexts/PublicDocumentSharePageContext";
import { downloadResource } from "../../utils/fetchUtils";
// import ListView from "./ListView";
import { PdfPreviewModalProvider } from "../../contexts/PdfPreviewModalContext";
import GridView from "./GridView";
import { usePDFMergeContext } from "../../contexts/PDFMergerContext";
import { useCallback } from "react";

const IS_SIGNED_URL = true;

interface Props {}
const GridOrList: React.FC<Props> = () => {
  const { print } = usePDFMergeContext();
  const { shareReq, documentInViewIndex, docDownloadUrls } =
    usePublicDocumentSharePageContext();

  const onPrintDocument = useCallback(async () => {
    await print(docDownloadUrls.docUrls, docDownloadUrls.imgUrls, IS_SIGNED_URL);
  }, [docDownloadUrls.docUrls, docDownloadUrls.imgUrls, print]);

  if (documentInViewIndex >= 0) {
    // render single document view
    const document = get(shareReq, ["documents", documentInViewIndex]);
    const downloadURL = get(shareReq, ["signedURLs", documentInViewIndex]);

    const onDownloadClick = async () => {
      await downloadResource(downloadURL, get(document, "doc_name", ""));
    };

    if (!document) return null;
    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: 800,
          mx: "auto",
          mt: 5,
          minHeight: 500,
        }}
      >
        <DocumentViewer
          url={downloadURL}
          file_format={document.doc_format ? document.doc_format : ""}
          isViewOnly={shareReq?.permission === "viewOnly"}
          onDownloadClick={onDownloadClick}
          onPrintClick={onPrintDocument}
          isSignedURL={true}
        />
      </Box>
    );
  }
  return (
    <PdfPreviewModalProvider>
      <GridView />
    </PdfPreviewModalProvider>
  );
};
export default GridOrList;
