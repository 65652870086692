import "./App.css";
import LogIn from "./components/LogIn";
import LogOut from "./components/LogOut";
import SignUp from "./components/SignUp";
import ProfileSetup from "./components/ProfileSetup";
import ProfileSetupLayout from "./components/ProfileSetupLayout";
import DashboardLayout from "./components/DashboardLayout";
import LogInFlowLayout from "./components/LogInFlowLayout";
import Documents from "./components/Documents";
import DashboardMain from "./components/DashboardMain";
import DashboardGallery from "./components/DashboardGallery";
import ForgotPassword from "./components/ForgotPassword";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import theme from "./assets/theme/theme";
import { ThemeProvider } from "@mui/material";
import { AuthProvider } from "./contexts/AuthContext";
import { DocumentProvider } from "./contexts/DocumentContext";
import PreventRefreshDialogContext from "./contexts/PreventRefreshDialogContext";
import EmailVerification from "./components/EmailVerification";
import DCPage from "./components/DCPage";
import LegalSupport from "./components/LegalSupport/LegalSupport";
import TravelHistory from "./components/TravelHistory/TravelHistory";
import DocumentUploadProvider from "./contexts/DocumentUploadContext";
import AppBarLayout from "./components/AppBarLayout";
import DocumentSharePage from "./components/DocumentSharePage";
import { DocumentGalleryProvider } from "./contexts/DocumentGalleryContext";
import { DocumentShareModalProvider } from "./contexts/DocumentShareModalContext";
import { PublicDocumentSharePageProvider } from "./contexts/PublicDocumentSharePageContext";
import constants from "./constants";
import { UserProvider } from "./contexts/UserContext";
import { UserProfileProvider } from "./contexts/UserProfileContext";
import { DocumentDeleteModalProvider } from "./contexts/DocumentDeleteModalContext";
import { ScanningTipsModalProvider } from "./contexts/ScanningTipsModalContext";
import { PdfPreviewModalProvider } from "./contexts/PdfPreviewModalContext";
import FAQPage from "./components/FAQPage";
import Page404 from "./components/Page404";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { TravelHistoryProvider } from "./contexts/TravelHistoryContext";
import EnrollMFA from "./components/EnrollMFA";
import VerifyMfa from "./components/VerifyMfa";
import LogOutTimerProvider from "./contexts/LogOutTimer";
import Payment from "./components/Payment/Payment";
import { PaymentContextProvider } from "./contexts/PaymentContext";
import PaymentUser from "./components/Payment/PaymentUser";
import { WalkThroughModalProvider } from "./contexts/WalkThroughModalContext";
import { SnackbarProvider } from "notistack";
import ManualEditRequiredCombinedSnackbar from "./components/DashboardLayout/ManualEditRequiredCombinedSnackbar";
import DocumentExpiryCombinedSnackbar from "./components/DashboardLayout/DocumentExpiryCombinedSnackbar";
import { ErrorBoundary } from "react-error-boundary";
import Error from "./components/Error/Error";
import { TravelHistoryNew } from "./components/TravelHistoryNew/TravelHistoryNew";
import { TravelHistoryProviderNew } from "./components/TravelHistoryNew/TravelHistoryContextNew";
import NewNotificationArrivedSnackbar from "./components/DashboardLayout/NewNotificationArrivedSnackbar";
import * as Sentry from "@sentry/react";
import { getAppConfig } from "./utils/environment";
import TermsAndCondition from "./components/TermsAndCondition/TermsAndCondition";
import { Success } from "./components/Payment/Success";
import { CancelPayment } from "./components/Payment/Cancel";
import { GetExpertHelp } from "./components/GetExpertHelp/GetExpertHelp";
import VerifyMfaDelete from "./components/VerifyMfaDelete";
import AccountDeleteConfirmationPage from "./components/AccountDeleteConfirmationPage";

declare module "notistack" {
  interface VariantOverrides {
    manualEditRequiredCombinedSnackbar: true;
    documentExpiryCombinedSnackbar: true;
    newNotificationArrivedSnackbar: true;
  }
}

if (process.env.REACT_APP_ENV === "prod") {
  Sentry.init({
    dsn: getAppConfig().sentry.dns,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [constants.docuCombLink, constants.apiLink],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function App() {
  return (
    <ErrorBoundary fallback={<Error />}>
      <SnackbarProvider
        // preventDuplicate
        Components={{
          manualEditRequiredCombinedSnackbar:
            ManualEditRequiredCombinedSnackbar,
          documentExpiryCombinedSnackbar: DocumentExpiryCombinedSnackbar,
          newNotificationArrivedSnackbar: NewNotificationArrivedSnackbar,
        }}
      >
        <div className="App">
          <PreventRefreshDialogContext>
            <LogOutTimerProvider>
              <Router>
                <Switch>
                  <AuthProvider>
                    <UserProvider>
                      <DocumentProvider>
                        <PaymentContextProvider>
                          <ThemeProvider theme={theme}>
                            <ScanningTipsModalProvider>
                              <WalkThroughModalProvider>
                                <Switch>
                                  {/* LogIn Flow Routes */}
                                  <Route path="/signup" exact>
                                    <DCPage
                                      authRequired={false}
                                      layout={LogInFlowLayout}
                                      pageId={constants.pages.signup}
                                    >
                                      <SignUp />
                                    </DCPage>
                                  </Route>

                                  <Route path="/login" exact>
                                    <DCPage
                                      authRequired={false}
                                      layout={LogInFlowLayout}
                                      pageId={constants.pages.login}
                                    >
                                      <LogIn />
                                    </DCPage>
                                  </Route>

                                  <Route path="/faqs" exact>
                                    <DCPage
                                      authRequired={false}
                                      layout={LogInFlowLayout}
                                      pageId={constants.pages.faqs}
                                    >
                                      <FAQPage />
                                    </DCPage>
                                  </Route>

                                  <Route path="/privacy-policy" exact>
                                    <DCPage
                                      authRequired={false}
                                      layout={LogInFlowLayout}
                                      pageId={constants.pages.privacyPolicy}
                                    >
                                      <PrivacyPolicy />
                                    </DCPage>
                                  </Route>
                                  <Route path="/terms-and-condition" exact>
                                    <DCPage
                                      authRequired={false}
                                      layout={LogInFlowLayout}
                                      pageId={constants.pages.termsAndCondition}
                                    >
                                      <TermsAndCondition />
                                    </DCPage>
                                  </Route>

                                  <Route path="/email-verify" exact>
                                    <DCPage
                                      authRequired={true}
                                      emailVerifyRequired={false}
                                      layout={LogInFlowLayout}
                                      pageId={constants.pages.emailVerify}
                                    >
                                      <EmailVerification />
                                    </DCPage>
                                  </Route>

                                  <Route path="/forgot-password" exact>
                                    <DCPage
                                      authRequired={false}
                                      layout={LogInFlowLayout}
                                      pageId={constants.pages.forgotPassword}
                                    >
                                      <ForgotPassword />
                                    </DCPage>
                                  </Route>

                                  <Route path="/enroll-mfa" exact>
                                    <DCPage
                                      authRequired={true}
                                      layout={LogInFlowLayout}
                                      pageId={constants.pages.enrollMFA}
                                    >
                                      <EnrollMFA />
                                    </DCPage>
                                  </Route>
                                  <Route path="/verify-mfa" exact>
                                    <DCPage
                                      authRequired={false}
                                      layout={LogInFlowLayout}
                                      pageId={constants.pages.verifyMfa}
                                    >
                                      <VerifyMfa />
                                    </DCPage>
                                  </Route>
                                  <Route path="/verify-mfa-delete" exact>
                                    <DCPage
                                      authRequired={true}
                                      layout={LogInFlowLayout}
                                      pageId={constants.pages.verifyMfaDelete}
                                    >
                                      <VerifyMfaDelete />
                                    </DCPage>
                                  </Route>
                                  <Route path="/account-delete-confirmation" exact>
                                    <DCPage
                                      authRequired={false}
                                      layout={LogInFlowLayout}
                                      pageId={constants.pages.deleteConfirmationPage}
                                    >
                                      <AccountDeleteConfirmationPage />
                                    </DCPage>
                                  </Route>
                                  <Route path="/logout">
                                    <LogOut />
                                  </Route>

                                  <Route path="/shared/:shareReqId" exact>
                                    <PublicDocumentSharePageProvider>
                                      <DCPage
                                        authRequired={false}
                                        layout={AppBarLayout}
                                        pageId={constants.pages.sharedDocuments}
                                      >
                                        <DocumentSharePage />
                                      </DCPage>
                                    </PublicDocumentSharePageProvider>
                                  </Route>

                                  {/*--------- Document Upload Provider -----------*/}

                                  <DocumentUploadProvider>
                                    <Switch>
                                      <Route path="/legal-support" exact>
                                        <DCPage
                                          authRequired={true}
                                          layout={DashboardLayout}
                                          pageId={constants.pages.legalSupport}
                                        >
                                          <LegalSupport />
                                        </DCPage>
                                      </Route>
                                      <Route path="/travel-history" exact>
                                        <DCPage
                                          authRequired={true}
                                          layout={DashboardLayout}
                                          pageId={constants.pages.travelHistory}
                                        >
                                          <TravelHistoryProviderNew>
                                            <TravelHistoryNew />
                                          </TravelHistoryProviderNew>
                                        </DCPage>
                                      </Route>
                                      <Route path="/travel-history-old" exact>
                                        <DCPage
                                          authRequired={true}
                                          layout={DashboardLayout}
                                          pageId={constants.pages.travelHistory}
                                        >
                                          <TravelHistoryProvider>
                                            <TravelHistory />
                                          </TravelHistoryProvider>
                                        </DCPage>
                                      </Route>

                                      <Route path="/payment" exact>
                                        <DCPage
                                          authRequired={true}
                                          layout={DashboardLayout}
                                          pageId={constants.pages.payment}
                                        >
                                          <Payment />
                                        </DCPage>
                                      </Route>

                                      <Route path="/payment/success" exact>
                                        <DCPage
                                          authRequired={false}
                                          layout={AppBarLayout}
                                          pageId={constants.pages.payment}
                                        >
                                          <Success />
                                        </DCPage>
                                      </Route>

                                      <Route path="/payment/cancel" exact>
                                        <DCPage
                                          authRequired={false}
                                          layout={AppBarLayout}
                                          pageId={constants.pages.payment}
                                        >
                                          <CancelPayment />
                                        </DCPage>
                                      </Route>

                                      <Route path="/payment/user" exact>
                                        <DCPage
                                          authRequired={true}
                                          layout={DashboardLayout}
                                          pageId={constants.pages.paymentUser}
                                        >
                                          <PaymentUser />
                                        </DCPage>
                                      </Route>

                                      {/* User Profile Setup Routes  */}
                                      <Route path="/profile/setup">
                                        <DCPage
                                          authRequired={true}
                                          layout={ProfileSetupLayout}
                                          pageId={
                                            constants.pages.userProfileSetup
                                          }
                                        >
                                          <UserProfileProvider>
                                            <ProfileSetup />
                                          </UserProfileProvider>
                                        </DCPage>
                                      </Route>

                                      {/* Dashboard Routes */}
                                      <Route path="/" exact>
                                        <DCPage
                                          authRequired={true}
                                          layout={DashboardLayout}
                                          pageId={constants.pages.dashboard}
                                        >
                                          <DocumentShareModalProvider>
                                            <DocumentDeleteModalProvider>
                                              <PdfPreviewModalProvider>
                                                <DashboardMain />
                                              </PdfPreviewModalProvider>
                                            </DocumentDeleteModalProvider>
                                          </DocumentShareModalProvider>
                                        </DCPage>
                                      </Route>
                                      <Route path="/documents/:docType" exact>
                                        <DCPage
                                          authRequired={true}
                                          layout={DashboardLayout}
                                          pageId={
                                            constants.pages.viewUploadDocs
                                          }
                                        >
                                          <DocumentGalleryProvider>
                                            <DocumentShareModalProvider>
                                              <DocumentDeleteModalProvider>
                                                <Documents />
                                              </DocumentDeleteModalProvider>
                                            </DocumentShareModalProvider>
                                          </DocumentGalleryProvider>
                                        </DCPage>
                                      </Route>

                                      <Route path="/documents" exact>
                                        <DCPage
                                          authRequired={true}
                                          layout={DashboardLayout}
                                          pageId={
                                            constants.pages.viewUploadDocs
                                          }
                                        >
                                          <DashboardGallery />
                                        </DCPage>
                                      </Route>

                                      <Route path="/get-expert-help" exact>
                                        <DCPage
                                          authRequired={true}
                                          layout={DashboardLayout}
                                          pageId={constants.pages.getExpertHelp}
                                        >
                                          <GetExpertHelp />
                                        </DCPage>
                                      </Route>

                                      <Route component={Page404} />
                                    </Switch>
                                  </DocumentUploadProvider>
                                </Switch>
                              </WalkThroughModalProvider>
                            </ScanningTipsModalProvider>
                          </ThemeProvider>
                        </PaymentContextProvider>
                      </DocumentProvider>
                    </UserProvider>
                  </AuthProvider>
                </Switch>
              </Router>
            </LogOutTimerProvider>
          </PreventRefreshDialogContext>
        </div>
      </SnackbarProvider>
    </ErrorBoundary>
  );
}

export default App;
