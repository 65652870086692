import {
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Box,
  styled,
} from "@mui/material";
import React from "react";
import theme, { DCButton } from "../../assets/theme/theme";
import { DrawerItems } from "./DrawerItems";
import Copyrights from "../Shared/Copyrights";
import { useHistory } from "react-router-dom";
import { useDocumentContext } from "../../contexts/DocumentContext";
import constants from "../../constants";
import { useSidebarContext } from "../../contexts/SidebarContext";
import UploadIcon from "../Shared/Icons/UploadIcon";
import { useDropzoneContext } from "../../contexts/DropzoneContext";

const Input = styled("input")({
  display: "none",
});

export interface Props {
  pageId: string;
}

const drawerWidth = 240;

const Sidebar: React.FC<Props> = (props) => {
  const { open, toggleSidebar } = useSidebarContext();
  const { open: openDropZone, getInputProps } = useDropzoneContext();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const history = useHistory();
  const {
    initializedDocumentsAndFolders: initalizedDocumentsAndFolders,
    documents,
  } = useDocumentContext();

  const disabledItem =
    initalizedDocumentsAndFolders && documents.length === 0
      ? constants.pages.dashboard
      : "";
  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd ? drawerWidth : 0,
        display: "relative",
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={open}
        onClose={toggleSidebar}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            zIndex: 2,
            display: "relative",
            color: theme.palette.text.primary,
            paddingBlock: 2,
            height: matchUpMd ? "calc(100% - 64px)" : "100%",
            top: matchUpMd ? "64px" : 0,
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        <Input {...getInputProps()} />
        <Box px={2}>
          <DCButton
            id="upload-docs"
            fullWidth
            startIcon={<UploadIcon />}
            variant="contained"
            color="primary"
            onClick={() => {
              openDropZone();
            }}
            size="large"
            sx={{ mb: 1 }}
          >
            Upload Docs
          </DCButton>
        </Box>

        {DrawerItems.map((item, i) => {
          if (item.type === "button") {
            return (
              <Box key={i} sx={{ marginInline: 2, display: "relative" }}>
                <Button
                  fullWidth
                  startIcon={item.getIcon()}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push(item.href);
                  }}
                  size="large"
                  sx={{
                    borderRadius: "10px",
                    paddingInline: "18px",
                    height: 46,
                    boxShadow: "none",
                    fontWeight: 400,
                  }}
                >
                  {item.text}
                </Button>
              </Box>
            );
          }
          return null;
        })}
        <Box mx={2}>
          <List component="nav" aria-label="Docucomb Sidebar">
            {DrawerItems.map((item, i) => {
              if (item.type !== "button") {
                return (
                  <ListItemButton
                    disabled={item.pageId === disabledItem || item.disabled}
                    component={item.component}
                    to={item.href}
                    href={item.href}
                    {...(item.attributes || {})}
                    key={i}
                    selected={item.pageId === props.pageId}
                  >
                    <ListItemIcon>
                      {item.pageId === props.pageId
                        ? item.getActiveIcon()
                        : item.getIcon()}
                    </ListItemIcon>

                    <ListItemText primary={item.text} />

                    {!!item.getBadge ? item.getBadge() : null}
                  </ListItemButton>
                );
              }
              return null;
            })}
          </List>
          {/* <List>
              <Button
              fullWidth
              // startIcon={item.getIcon()}
              variant='text'
              color='primary'
              onClick={() => {
                history.push('/privacy-policy')
              }}
              size='large'
              sx={{
                borderRadius: '10px',
                paddingInline: '18px',
                height: 46,
                boxShadow: 'none',
                fontWeight: 400,
                position : 'absolute',
                bottom :5,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                  color: theme.palette.primary.main,
                  boxShadow: 'none'
              }
              }}>
                Privacy Policy
              </Button>
            </List> */}
        </Box>
        <Copyrights />
      </Drawer>
    </Box>
  );
};

export default Sidebar;
