import {
  Autocomplete,
  Card,
  createTheme,
  IconButton,
  InputBase,
  ListItem,
  Menu,
  Paper,
  Stepper,
  Tabs,
  Badge,
  ToggleButtonGroup,
  Button,
  Dialog,
  TextField,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { borderColor, Box } from "@mui/system";
import "@mui/material/styles/createPalette";
import LoadingButton from "@mui/lab/LoadingButton";

declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    disabledText: string;
    disabled: string;
    border: string;
    disabledButton: string;
  }
}

const cssVar = (name: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(name)?.trim();

const fontFamily =
  'Proxima Nova, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
const bg = cssVar("--bg");
const primary = cssVar("--primary");
const white = cssVar("--white");
const primaryDark = cssVar("--primary-dark");
const primaryDarkest = cssVar("--primary-darkest");
const primaryLight = cssVar("--primary-light");
const text = cssVar("--text");
const icon = cssVar("--icon");
const textDark = cssVar("--textDark");
const secondary = cssVar("--gray");
const disabled = cssVar("--disabled");
const disabledText = cssVar("--disabled-text");
const disabledButton = cssVar("--disabled-button");
const border = cssVar("--border");
const success = cssVar("--success");
const successBg = cssVar("--success-bg");
const error = cssVar("--error");
const errorBg = cssVar("--error-bg");
const shadowSm = cssVar("--shadowSm");
const shadow = cssVar("--shadow");
const shadowMd = cssVar("--shadowMd");
const shadowLg = cssVar("--shadowLg");
const shadowXl = cssVar("--shadowXl");
const shadow2xl = cssVar("--shadow2xl");
const shadowInner = cssVar("--shadowInner");

const theme = createTheme({
  palette: {
    common: {
      disabledText,
      disabled,
      border,
      disabledButton,
    },
    primary: {
      main: primary,
      light: primaryLight,
      dark: primaryDark,
      900: primaryDarkest,
    },
    error: {
      light: "#FFEBEE",
      main: "#E53935",
      dark: "#C62828",
      // contrastText: "#E53935", // commented because it breaks document delete btn
    },
    success: {
      main: success,
      light: successBg,
      dark: success,
    },
    text: {
      primary: text,
      secondary: secondary,
    },
    action: {
      disabledBackground: white,
      disabled: disabledText,
    },
    background: {
      default: bg,
    },
    divider: border,
  },

  typography: {
    fontFamily: [fontFamily].join(","),
    fontSize: 14, // default font size
    htmlFontSize: 16, // 1rem = 16px
    h1: {
      fontSize: "2.125rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.75rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    body1: {
      fontSize: ".875rem",
      fontFamily: [fontFamily].join(","),
    },
    body2: {
      fontSize: ".75rem",
    },
    button: {
      fontFamily,
      fontSize: ".875rem",
      fontWeight: 500,
      lineHeight: 2,
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(2px)",
          },
        },
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "24px 24px 16px",
          fontSize: "1.125rem",
          fontWeight: 500,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "8px 24px 0px !important",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: ".875rem",
          transform: "translate(0, -1px) scale(1)",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: primary,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "10px 16px",
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          position: "relative",
          padding: "10px 12px 10px 16px",
          marginBottom: 8,
          color: text,
          "& .MuiListItemIcon-root": {
            color: "inherit",
          },
          "&:hover": {
            color: primary,
            background: "transparent",
          },
          "&.Mui-selected": {
            borderRadius: 12,
            "& .MuiTypography-root": {
              color: primary,
              fontWeight: 500,
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderColor: border,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: 12,
          minWidth: 0,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        primary: {
          fontSize: 14,
          whiteSpace: "nowrap",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          "& .MuiBadge-badge": {
            fontSize: ".75rem",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          fontFamily,
          textTransform: "none",
          fontSize: 14,
          borderRadius: 12,
          minWidth: 100,
          "&.dc-button--tertiary": {
            backgroundColor: white,
            color: text,
            boxShadow: `0 0 0 1px ${border}, ${shadowSm}`,
            "&:hover": {
              backgroundColor: bg,
              borderColor: primaryLight,
            },
            "&.Mui-disabled": {
              backgroundColor: disabledButton,
              cursor: "not-allowed",
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: text, //'#fff', //'hsla(0, 0%, 20%, 0.9)',
          color: white,
          fontSize: "14px",
          lineHeight: 1.6,
        },
        arrow: {
          color: text,
        },
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          color: text,
          "&.Mui-checked": {
            color: text,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 40,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 40,
          padding: 0,
          marginRight: 20,
          textTransform: "none",
          color: text,
          "&.Mui-selected": {
            color: primary,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: text,
          fontSize: 14,
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          "&.MuiChip-filledPrimary": {
            backgroundColor: primaryLight,
            color: primary,
            "&:hover": {
              color: white,
              backgroundColor: primary,
            },
            "&.MuiButtonBase-root, .MuiChip-root,  .MuiChip-deleteIcon": {
              color: primary,
              "&:hover *": {
                color: white,
              },
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "12px !important",
          boxShadow: `0 0 0 1px rgb(56 60 67 / 5%), 0 1px 3px 0 rgb(56 60 67 / 15%) !important`,
          marginTop: 8,
        },
        root: {
          "& .MuiList-padding": {
            paddingBlock: 6,
          },
          "& .MuiMenuItem-root": {
            borderRadius: 8,
            marginInline: 6,
            paddingInline: 12,
            paddingBlock: 8,
            "+ .MuiMenuItem-root": {
              marginTop: 2,
            },
          },
          "& .MuiListItemIcon-root": {
            marginBlock: 2,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid  ${border}`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 12,
          boxShadow:
            "0 0 0 1px rgb(56 60 67 / 5%), 0 1px 3px 0 rgb(56 60 67 / 15%)",
        },
      },
    },
  },
});

const shadows = theme.shadows;
shadows[1] = shadow;
shadows[2] = shadowSm;
shadows[3] = shadowMd;
shadows[4] = shadowLg;
shadows[5] = shadowXl;
shadows[6] = shadow2xl;
shadows[7] = shadowInner;
export const ScrollableBox = styled(Box)(({ theme }) => ({
  // '&::-webkit-scrollbar': {
  //   width: '18px',
  //   backgroundColor: '#fff',
  // },
  // '&::-webkit-scrollbar-track': {
  //   backgroundColor: '#fff'
  // },
  // '&::-webkit-scrollbar-thumb:hover': {
  //   backgroundColor: '#a0a0a5'
  // },
  // // thumb is a solid bar which moves up and down
  // '&::-webkit-scrollbar-thumb': {
  //   backgroundColor: '#babac0',
  //   borderRadius: '16px',
  //   border: '3px solid #fff',
  // },
}));

export const DcInput = styled(InputBase)(({ theme }) => ({
  "&.MuiInputBase-root": {
    padding: 0,
  },
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "&.Mui-error": {
    "& .MuiInputBase-input": {
      borderColor: theme.palette.error.main,
    },
  },
  "& .MuiInputBase-input": {
    background: theme.palette.common.white,
    borderRadius: 6,
    position: "relative",
    border: `1px solid ${border}`,
    fontSize: 14,
    width: "100%",
    padding: "10px 12px",
    height: 22,
    boxShadow: "0 1px 3px rgba(132, 183, 252, .16)",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily,
    "&:focus": {
      borderColor: primary,
      borderRadius: 6,
    },
    "&:disabled": {
      color: disabledText,
      WebkitTextFillColor: disabledText,
      pointer: "not-allowed",
    },
  },
}));

export const DcIconButton = styled(IconButton)({
  width: 40,
  height: 40,
});

export const DcIconBox = styled(Box)({
  color: theme.palette.text.primary,
  display: "flex",
});

export const UploadListItem = styled(ListItem)({
  "&.MuiListItem-root": {
    padding: "20px 0 20px 20px",
  },
  "& .MuiListItemText-primary": {
    fontSize: 16,
    fontWeight: 500,
  },
  "& .MuiListItemText-secondary": {
    fontSize: 16,
  },
});
export default theme;

export const ViewSelectorButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    padding: 2,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 12,
    "& .MuiToggleButtonGroup-grouped": {
      height: 34,
      border: 0,
      width: 52,
      color: theme.palette.text.primary,
      opacity: 1,
      "& .MuiSvgIcon-root": {
        fontSize: 18,
      },
      "&.Mui-disabled": {
        border: 0,
      },
      "&:not(:first-of-type)": {
        borderRadius: 10,
      },
      "&:first-of-type": {
        borderRadius: 10,
      },
      "&.Mui-selected": {
        background: primary,
        color: theme.palette.common.white,
        "&:hover": {
          background: primary,
        },
      },
    },
  })
);

export const DCDocumentCard = styled(Card)({
  width: "100%",
  borderRadius: 10,
  maxwidth: 320,
  boxShadow: "0 0 0 1px rgb(56 60 67 / 5%), 0 1px 3px 0 rgb(56 60 67 / 15%)",
  border: 0,
  transition: theme.transitions.create(["border-color", "box-shadow"]),
  "&:hover": {
    boxShadow: "0 0 0 0px rgb(56 60 67 / 5%), 0 3px 10px 0 rgb(56 60 67 / 15%)",
  },
  "&.selected": {
    boxShadow: "0 0 0 0px rgb(56 60 67 / 5%), 0 3px 10px 0 rgb(56 60 67 / 15%)",
  },
});

export const DcDataGrid = styled(DataGrid)({
  border: 0,
  fontSize: 16,
  color: secondary,
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.grey[100],
    border: "0 !important",
    minHeight: "54px !important",
    height: 54,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 400,
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${border}`,
  },
  "& .MuiDataGrid-row:hover": {
    background: primaryLight,
    transition: theme.transitions.create(["background-color"]),
  },
});

export const DcDataGridTravelHistory = styled(DataGrid)({
  border: 0,
  fontSize: 16,
  color: secondary,
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.primary.light,
    border: "0 !important",
    minHeight: "54px !important",
    height: 54,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 400,
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${borderColor}`,
  },
  "& .MuiDataGrid-row:hover": {
    background: primaryLight,
    transition: theme.transitions.create(["background-color"]),
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    padding: "0 !important",
  },
});

export const DcDocumentIconButton = styled(IconButton)({
  width: 40,
  height: 40,
});

export const DCAutocomplete = styled(Autocomplete)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 6,
    padding: "10px 12px",
    background: theme.palette.common.white,
    boxShadow: "0 1px 3px rgba(132, 183, 252, .16)",
    "& .MuiAutocomplete-input": {
      padding: "2px 4px 2px 6px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: border,
    },
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: border,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 1,
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: border,
      },
    },
  },
  "& .MuiAutocomplete-tag": {
    margin: "3px 5px",
  },
  "& .MuiAutocomplete-endAdornment": { top: "calc(50% - 14px)" },
});

export const DCAutocompleteTextField = styled(TextField)({
  "& .MuiTextField-root": {
    fontFamily: fontFamily,
  },
});

export const DCTextBox = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 8,
  },
  "& .MuiInputBase-input": {
    background: theme.palette.common.white,
    borderRadius: 8,
    position: "relative",
    border: `1px solid ${border}`,
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    height: 24,
    boxShadow: "0 1px 3px rgba(132, 183, 252, .16)",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      borderColor: primary,
    },
    "&:disabled": {
      background: disabled,
      color: text,
      WebkitTextFillColor: text,
      pointer: "not-allowed",
    },
  },
});

export const DCMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 6,
    boxShadow: "0 1px 6px rgba(0,0,0,.16)",
    top: "64px !important",
    // backgroundColor: "#eeeded",
  },
  "& .MuiList-root": {
    padding: 5,
    "& .MuiDivider-root": {
      margin: 0,
    },
  },
});

export const NotificationItem = styled(Paper)({
  padding: "16px 20px ",
  marginBottom: "3px",
  "&.unread-status": {
    background: primaryLight,
    fontWeight: "bold",
  },
  "& .notification-dialog-link": {
    textDecoration: "none",
    fontWeight: 500,
    color: theme.palette.primary.main,
    fontSize: "1rem",
    margin: "0px -7px 1px -7px",
  },
});
export const DCDashboardPageHeader = styled(Paper)({
  background: primaryLight,
  borderRadius: 0,
  padding: "32px 32px 0 32px",
  position: "relative",
  boxShadow: "none",
  "& .page-header--ilustration": {
    position: "absolute",
    right: 0,
  },
});
export const DCDashboardContainer = styled(Box)({
  padding: 32,
    '@media (max-width: 767px)': {
    padding: 15,
  },
});

export const DCDashboardContent = styled(Box)({
  position: "relative",
  paddingInline: 23,
  zIndex: 1,
  minHeight: "calc(100% - 143px)",
});

export const DCPageSummary = styled(Paper)({
  boxShadow:
    "rgba(42, 65, 205, 0.16) 0px 1px 4px, rgba(42, 65, 205, 0.12) 0px 1px 3px -1px",
  borderRadius: 16,
  padding: 16,
  minHeight: 180,
  display: "flex",
  "& .immigration-summary--illustration": {
    position: "absolute",
    left: -33,
    top: -75,
  },
  "&   .summary-datalist": {
    margin: 0,
  },
});

export const DCDocumentStackCard = styled(Card)({
  boxShadow: "0 0 0 1px rgb(56 60 67 / 5%), 0 1px 3px 0 rgb(56 60 67 / 15%)",
  borderRadius: 16,
  minHeight: "345px",
  marginTop: 15,
  position: "relative",
  overflow: "visible",

  "&::before, &::after": {
    borderRadius: "15px 15px 0 0",
    background: theme.palette.common.white,
    border: `solid ${theme.palette.grey[100]}`,
    borderWidth: "0 0 3px",
    content: '""',
    position: "absolute",
    top: -11,
    left: 20,
    height: 8,
    width: "calc(100% - 40px)",
  },
  "&::after": {
    width: "calc(100% - 80px)",
    top: -22,
    left: 40,
  },

  "& .MuiCardHeader-root ": {
    padding: "28px 28px 16px 28px",
  },
  "& .MuiCardContent-root": {
    padding: "0 28px",
  },
  "& .MuiCardHeader-root .MuiTypography-root": {
    fontSize: 22,
  },
  "& .MuiCardActions-root": {
    padding: 20,
    "& .showmore-button": {
      height: 32,
      padding: "2px 16px",
      borderRadius: 16,

      "& img": {
        transform: "rotate(180deg)",
        "&.rotate-chevron": {
          transform: "rotate(0deg)",
        },
      },
    },
  },
});

export const DCBaseCard = styled(Card)({
  // border: `1px solid ${border}`,
  // boxShadow: "rgba(42, 65, 205, 0.16) 0px 1px 4px, rgba(42, 65, 205, 0.12) 0px 1px 3px -1px",
  borderRadius: 16,
  boxShadow: "0 2px 3px rgb(56 60 67 / 8%)",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  transition: theme.transitions.create(["box-shadow", "border-color"]),
  overflow: "visible",
  "&:hover": {
    boxShadow: "0 0 0 0px rgb(56 60 67 / 5%), 0 3px 10px 0 rgb(56 60 67 / 15%)",
  },
  "&.is-stacked:before": {
    height: 9,
    width: "calc(100% - 32px)",
    top: -11,
    position: "absolute",
    background: theme.palette.common.white,
    left: 16,
    borderRadius: "16px 16px 0 0",
    content: '""',
  },
  "&.is-stacked:after": {
    height: 9,
    width: "calc(100% - 64px)",
    top: -21,
    position: "absolute",
    background: theme.palette.common.white,
    left: 32,
    borderRadius: "32px 32px 0 0",
    content: '""',
  },
  "& .MuiCardHeader-root": {
    padding: 20,
    "& .MuiCardHeader-avatar": {
      alignSelf: "flex-start",
      position: "relative",
      top: 1,
    },
    "& .MuiTypography-root": {
      fontSize: 18,
      fontWeight: 500,
    },
  },
  "& .MuiCardContent-root": {
    paddingInline: 20,
    paddingBlock: 0,
  },
  "& .MuiCardActions-root": {
    padding: 8,
    paddingBlock: 20,
    justifyContent: "space-between",
    "& .showmore-button.MuiButton-root": {
      background: border,
      boxShadow: "none",
      color: text,
      height: 32,
      minHeight: "unset",
      padding: "2px 12px",
      borderRadius: 16,
      textTransform: "none",
      marginLeft: 12,
      "& img": {
        transform: "rotate(180deg)",
        "&.rotate-chevron": {
          transform: "rotate(0deg)",
        },
      },
    },
  },
});
export const DCCustomCard = styled(Card)({
  boxShadow:
    "rgba(42, 65, 205, 0.16) 0px 1px 4px, rgba(42, 65, 205, 0.12) 0px 1px 3px -1px",
  borderRadius: 16,
  // height: '100%',
  position: "relative",
  // "&::before, &::after": {
  //   borderRadius: "8px 8px 0 0",
  //   border: `solid ${theme.palette.grey[100]}`,
  //   borderWidth: "0 0 3px",
  //   content: '""',
  //   position: "absolute",
  //   top: -11,
  //   left: 20,
  //   height: 8,
  //   width: "calc(100% - 40px)",
  // },
  // "&::after": {
  //   width: "calc(100% - 80px)",
  //   top: -22,
  //   left: 40,
  // },
  "& .MuiCardHeader-root ": {
    padding: 24,
  },
  "& .MuiCardContent-root": {
    padding: 24,
    paddingTop: 0,
  },
  "& .MuiCardHeader-root .MuiTypography-root": {
    fontSize: 22,
  },
  "& .MuiCardActions-root": {
    padding: 8,
    paddingTop: 0,
    justifyContent: "space-between",
    "& .showmore-button.MuiButton-root": {
      height: 32,
      padding: "2px 12px",
      borderRadius: 16,
      marginLeft: 12,
      textTransform: "none",
      "& img": {
        transform: "rotate(180deg)",
        "&.rotate-chevron": {
          transform: "rotate(0deg)",
        },
      },
    },
  },
});

export const FormWrapper = styled(Box)({
  height: "100%",
  display: "flex",
  alignItems: "center",
});

export const DCStepper = styled(Stepper)({
  ".MuiStep-root": {
    width: "50%",
    padding: 0,
    ".MuiButtonBase-root": {
      justifyContent: "flex-start",
      paddingInline: 0,
    },
    ".MuiStepLabel-label": {
      fontSize: 16,
      textAlign: "left",
      color: theme.palette.text.primary,
      "&.Mui-active": {
        color: theme.palette.primary.main,
      },
    },
    "&.Mui-completed": {
      ".MuiStepIcon-root": {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
    "&:last-child": {
      paddingLeft: 32,
    },
  },
  ".MuiStepConnector-root": {
    display: "none",
  },

  ".MuiStepIcon-root": {
    height: 32,
    width: 32,
    color: theme.palette.common.white,
    border: "solid",
    borderColor: theme.palette.common.border,
    borderRadius: 25,
    borderWidth: 1,
    marginRight: 8,
    ".MuiStepIcon-text": {
      fill: theme.palette.text.primary,
      transform: "translate(0px, 1px)",
    },
    "&.Mui-active": {
      border: "solid",
      borderColor: theme.palette.primary.main,
      borderRadius: 25,
      fill: "white",
      borderWidth: 2,
      "& .MuiStepIcon-text": {
        fill: theme.palette.primary.main,
      },
    },
  },
});

export const DCStorageRadioButton = styled(Box)({
  background: theme.palette.grey[100],
  padding: theme.spacing(2.5),
  borderRadius: 20,
  transition: theme.transitions.create(["background-color", "box-shadow"]),
  "&:hover": {
    background: theme.palette.primary.light,
    boxShadow: `0 0 0 1.5px ${theme.palette.primary.main} inset`,
  },
  "& .MuiRadio-root": {
    margin: "-11px -11px 0",
  },
  "& .storage-icon": {
    position: "absolute",
    top: theme.spacing(2.5),
    right: theme.spacing(4),
  },
});

export const DCDockedUploadWindow = styled(Paper)((props) => ({
  position: "fixed",
  bottom: 0,
  maxWidth: "70%",
  [props.theme.breakpoints.up("md")]: {
    maxWidth: "45%"
  },
  right: theme.spacing(2.5),
  boxShadow: "0 1px 6px rgba(0,0,0,.16)",
  zIndex: 10,
  width: theme.spacing(75),
  borderRadius: "10px 10px 0 0",
  overflow: "hidden",

  "& .dockedwindow-header": {
    background: theme.palette.primary.main,
    // background: theme.palette.primary.light,
    padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
    cursor: "pointer",
    color: theme.palette.common.white,
    "& .MuiIconButton-root": {
      color: theme.palette.common.white,
    },
  },
  "& .dockedwindow-body": {
    maxHeight: "600px",
    overflowY: "auto",
  },
  "& .file-upload-status": {
    border: `1px solid ${theme.palette.grey[100]}`,
    width: theme.spacing(20),
    height: theme.spacing(3.75),
    borderRadius: 100,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "&.file-upload-status--button": {
      borderColor: theme.palette.primary.main,
    },
    "&.file-upload-status--success": {
      background: "#fff0ea",
      borderColor: "#fff0ea",
    },
    "&.file-upload-status--default": {
      width: theme.spacing(24),
      background: "none",
      borderColor: "none",
    },
    "& .progress": {
      background: theme.palette.primary.light,
      height: theme.spacing(4),
      borderRadius: 100,
      top: 0,
      left: 0,
      position: "absolute",
      transition: theme.transitions.create(["width"]),
    },
  },
  "& .file-cancel-button": {
    background: theme.palette.grey[50],
    height: 30,
    marginLeft: 12,
    width: 30,
    transition: theme.transitions.create(["background-color", "color"]),
    "&:hover": {
      background: theme.palette.error.main,
      color: theme.palette.common.white,
    },
  },
}));
export const DCShadowCard = styled(Box)({
  background: theme.palette.common.white,
  boxShadow: shadow,
  border: `1px solid ${border}`,
  borderRadius: 15,
});
export const DCSupportSidebarMenu = styled(Box)({
  borderRight: `1px solid ${theme.palette.common.border}`,
  flex: 1,
  height: `calc(100% - ${theme.spacing(3)})`,
  paddingTop: `${theme.spacing(3)}`,
  "& .support-sidebar--navlink": {
    gap: 20,
    display: "flex",
    padding: `${theme.spacing(1)} ${theme.spacing(6)}`,
    color: theme.palette.text.primary,
    alignItems: "center",
    transition: theme.transitions.create(["color"]),
    "&:hover, &.support-sidebar--navlink_active": {
      color: theme.palette.primary.main,
    },
  },
});
export const DCLegalSupportTabs = styled(Tabs)({
  "& .legal-support-tab": {
    lineHeight: 2,
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    textDecoration: "none",
    color: theme.palette.text.primary,
    textTransform: "uppercase",
    "&.legal-support-tab--selected": {
      color: theme.palette.primary.main,
    },
  },
});
export const DCLegalCheatSheetInfo = styled(Box)({
  "& .cheatsheet-title": {
    position: "relative",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "&:after": {
      background: theme.palette.primary.main,
      borderWidth: 1,
      width: theme.spacing(5),
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      height: 1,
    },
  },
});

export const DCHistorySelectWrapper = styled(Box)({
  "& .MuiInputBase-root": {
    marginTop: 0,
    width: 200,
  },
});

export const CancelBtnBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    height: 32,
    width: 32,
    right: 0,
    top: 13,
    borderRadius: 16,
    background: theme.palette.common.white,
    color: theme.palette.success.main,
    border: `2px solid ${theme.palette.grey[300]}`,
    fontSize: 16,
    padding: "0 4px",
    lineHeight: 1.1,
  },
});

export const DCButton = styled(Button)({
  boxShadow: "none",
  fontFamily,
  lineHeight: 2,
  textTransform: "none",
  fontSize: 14,
  borderRadius: 12,
  minWidth: 100,

  "&.MuiButton-containedPrimary ": {
    background: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: primaryDarkest,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: primaryDarkest,
      outline: "none",
    },
  },
  "&.Mui-disabled": {
    color: "#fff",
    background: disabled,
    cursor: "not-allowed",
  },
  "&.dc-button--small": {
    padding: "1px 12px",
  },
  "&.MuiButton-outlined": {
    border: `1px solid ${primary}`,
    backgroundColor: "transparent",
  },
  "&.dc-button--tertiary": {
    backgroundColor: white,
    color: text,
    boxShadow: `0 0 0 1px ${border}, ${shadowSm}`,
    "&:hover": {
      backgroundColor: bg,
      borderColor: primaryLight,
    },
  },
});

export const DCLoadingButton = styled(LoadingButton)({
  boxShadow: "none",
  fontFamily,
  textTransform: "none",
  padding: "10px 16px",
  fontSize: 14,
  lineHeight: 1.42857143,
  backgroundColor: primary,
  borderRadius: 12,
  minWidth: 100,
  "&:hover": {
    backgroundColor: primaryDarkest,
    boxShadow: "none",
  },
  "&.dc-button--small": {
    padding: "1px 12px !important",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: primaryDarkest,
    outline: "none",
  },
  "&.Mui-disabled": {
    color: disabledText,
    background: disabledButton,
  },
  "&.MuiButton-outlined": {
    border: `1px solid ${primary}`,
    backgroundColor: "transparent",
  },
});

export const DCTravelContent = styled(Box)({
  position: "relative",
  paddingInline: 60,
  minHeight: "calc(100% - 143px)",
});

export const DCPaper = styled(Paper)({
  boxShadow: "0 0 0 1px rgb(56 60 67 / 5%), 0 1px 3px 0 rgb(56 60 67 / 15%)",
  borderRadius: 16,
  width: "100%",
});

export const DCDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: 16,
    padding: 0,
    "& .MuiDialogTitle-root": {
      padding: "24px 24px 16px",
      "& .MuiTypography-root": {
        fontSize: "1.125rem",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "8px 24px 0px !important",
    },
    "& .MuiDialogActions-root": {
      padding: 24,
    },
  },
});

export const DCAlertToast = styled(Alert)({
  color: text,
  backgroundColor: white,
  boxShadow: shadowSm,
  borderRadius: 12,
  border: `2px solid transparent`,
  top: "70px",
  background: `linear-gradient(
    var(--white),
    var(--white)
  ) padding-box, linear-gradient(304.2deg, #455dee 16.52%, #6999f5 42.76%, #85e1f5 87.79%)
  border-box`
});

export const DCNotification = styled(Paper)({
  color: text,
  backgroundColor: white,
  boxShadow: shadowSm,
  borderRadius: 12,
  border: `2px solid transparent`,
  top: "70px",
  background: `linear-gradient(
    var(--white),
    var(--white)
  ) padding-box, linear-gradient(304.2deg, #455dee 16.52%, #6999f5 42.76%, #85e1f5 87.79%)
  border-box`
});
