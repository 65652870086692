import { LoadingButton, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import React, { FC, useEffect, useMemo, useState } from "react";
import theme, {
  DCAutocomplete,
  DcInput,
  DCAutocompleteTextField,
} from "../../assets/theme/theme";
import { DCDatePicker } from "../Shared/DCDatePicker";
import { Flag } from "../Shared/Icons/Flag";
import { DCFormLabel } from "../Shared/DCFormLabel";
import { dateTimeToSeconds, toDateTime } from "../../utils/dateTimeUtils";
import useTravelValidation from "./hooks/TravelValidations";
import { ITravelModel } from "./hooks/TravelHistory.hook";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import { AirportModel } from "../../models/AirportModel";
import { format } from "util";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const VisaStatusList = [
  "H-1B",
  "B-1",
  "B-2",
  "F-1",
  "L-1",
  "L-2",
  "O-1",
  "J-1",
  "M-1",
  "P",
  "E-3",
  "BCC",
  "D",
  "A",
  "G1",
  "G2",
  "G3",
  "G4",
  "G5",
  "NATO",
  "J",
  "J-2",
  "Q",
  "K-1",
  "A-2",
  "H-1B1",
  "I",
  "L",
  "TN/TD",
  "H-1C",
  "R",
  "F-2",
  "M-2",
  "H-2A",
  "H-2B",
  "H-3",
  "E-2",
  "E-1",
  "C",
  "T-1",
  "NATO1-6",
];

interface Props {
  travel: ITravelModel | null;
  airportList: AirportModel[];
  open: boolean;
  isWorldTravel?: boolean;
  onClose(): void;
  onSave(travel: ITravelModel): Promise<void>;
}

export const TraveModal: FC<Props> = ({
  travel,
  open,
  airportList,
  isWorldTravel,
  onClose,
  onSave,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isPresent, setIsPresent] = useState(false);
  const [departure, setDeparture] = useState<AirportModel | null>(null);
  const [arrival, setArrival] = useState<AirportModel | null>(null);
  const [visaStatus, setVisaStatus] = useState<string>("");
  const [departureDate, setDepartureDate] = useState<number>(0);
  const [arrivalDate, setArrivalDate] = useState<number>(0);
  const {
    shouldPresentActive,
    shouldDisableArrivalDate,
    shouldDisableDepartureDate,
  } = useTravelValidation();

  const onClickAdd = async () => {
    setIsSaving(true);
    // const formatedArrivalDate = date.split("-").reverse().join("-");
    
    await onSave({
      arrival_date: toDateTime(arrivalDate),
      arrival_loc: arrival?.airport_code || "",
      automated: false,
      country: arrival?.country || "",
      departure_date: toDateTime(departureDate),
      departure_loc: departure?.airport_code || "",
      visa_status: visaStatus,
      isPresent: isPresent,
      hasError: false,
      arrival_loc_label: arrival?.label || "",
      departure_loc_label: departure?.label || "",
    });
    setIsSaving(false);
    onClose();
  };
  const onChangeDeparturePlace = (val: AirportModel) => {
    setDeparture(val);
  };
  const onChangeArrivalPlace = (val: AirportModel) => {
    setArrival(val);
  };
  const onChangeVisaStatus = (val: string) => {
    setVisaStatus(val);
  };
  const onChangeArrivalDate = (_data: Date, val: number) => {
    setArrivalDate(val);
  };
  const onChangeDepartureDate = (_data: Date, val: number) => {
    setDepartureDate(val);
  };
  const onChangePresent = (val: boolean) => {
    if (val) {
      setDeparture(null);
      setDepartureDate(0);
    }
    setIsPresent(val);
  };
  const clear = () => {
    setDeparture(null);
    setArrival(null);
    setVisaStatus("");
    setDepartureDate(0);
    setArrivalDate(0);
    setIsPresent(false);
  };

  const isFormValid = useMemo(() => {
    const allFilled = Boolean(arrival && arrivalDate);
    if (allFilled && !isPresent) {
      return allFilled && departureDate && departure;
    }
    return allFilled;
  }, [departure, arrival, departureDate, arrivalDate, isPresent]);

  useEffect(() => {
    if (travel && open) {
      const selectedDeparture = airportList.find(
        (airPort) => airPort.airport_code === travel.departure_loc
      );
      const selectedArrival = airportList.find(
        (airPort) => airPort.airport_code === travel.arrival_loc
      );
      setDeparture(selectedDeparture || null);
      setArrival(selectedArrival || null);
      setVisaStatus(travel.visa_status);
      setIsPresent(travel.isPresent);

      if (!travel.isPresent && travel.departure_date) {
        setDepartureDate(dateTimeToSeconds(travel.departure_date));
      }
      travel.arrival_date &&
        setArrivalDate(dateTimeToSeconds(travel.arrival_date));
    }
  }, [open, travel, airportList]);

  useEffect(() => {
    if (!open) {
      clear();
    } else {
      logEvent(analytics, "tab_change", {
        tab_name: "USA Travels",
        page_title: "TravelHistory",
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1000px",
          },
        },
      }}
    >
      <DialogTitle>
          {travel ? "Edit" : "Create"}{!isWorldTravel? " USA": ""} Travel log
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DCFormLabel htmlFor="departing-city-country">
                Port Of Entry
              </DCFormLabel>
              <DCAutocomplete
                id="departing-city-country"
                options={airportList}
                value={arrival?.label}
                onChange={(_, val: any) => onChangeArrivalPlace(val)}
                renderInput={(params) => (
                  <DCAutocompleteTextField
                    {...params}
                    placeholder="Select City, Country"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <DCFormLabel htmlFor="visiting-city-country">
                Port Of Exit
              </DCFormLabel>
              <DCAutocomplete
                id="visiting-city-country"
                key={isPresent ? "departure_present" : "visiting-city-country"}
                options={airportList}
                disabled={isPresent}
                value={departure ? departure.label : ""}
                onChange={(e, val: any) => onChangeDeparturePlace(val)}
                renderInput={(params) => (
                  <DCAutocompleteTextField
                    {...params}
                    placeholder={
                      isPresent ? "Currently in the country" : "Select City, Country"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <DCFormLabel htmlFor="arrival-date">
                Arrival Date
                {arrival ? ` (${arrival?.country})` : ""}
                <Flag
                  code={arrival?.flag_code || ""}
                  width={20}
                  style={{ marginLeft: 8, marginBottom: -1 }}
                />
              </DCFormLabel>
              <DCDatePicker
                id="arrival-date"
                value={arrivalDate}
                onChange={onChangeArrivalDate}
                shouldDisableDate={shouldDisableArrivalDate}
              />
            </Grid>
            <Grid item xs={6}>
              <Box position="relative">
                <DCFormLabel htmlFor="departure-date">
                  Departure Date
                  {departure ? ` (${departure?.country})` : ""}
                  <Flag
                    code={departure?.flag_code || ""}
                    width={20}
                    style={{ marginLeft: 8, marginBottom: -1 }}
                  />
                </DCFormLabel>
                <DCDatePicker
                  id="departure-date"
                  value={departureDate}
                  disabled={!arrivalDate || isPresent}
                  placeholder={isPresent ? "In country" : undefined}
                  onChange={onChangeDepartureDate}
                  shouldDisableDate={(d) =>
                    shouldDisableDepartureDate(d, arrivalDate)
                  }
                />
                <Box position="absolute" bottom={-40}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={shouldPresentActive(travel)}
                        checked={isPresent}
                        onChange={(_, c) => onChangePresent(c)}
                      />
                    }
                    label="Currently in the country"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <DCFormLabel htmlFor="visa-status">Visa Type</DCFormLabel>
              <Box>
                {isWorldTravel ? (
                  <DcInput
                    id="visa-status"
                    fullWidth
                    value={visaStatus}
                    onChange={(e) => onChangeVisaStatus(e.target.value)}
                    placeholder="e.g. : H1B, F1, B2"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                ) : (
                  <Select
                    fullWidth
                    value={visaStatus}
                    onChange={(e) => onChangeVisaStatus(e.target.value)}
                    input={
                      <DcInput
                        id="visa-status"
                        placeholder="e.g. : H1B, F1, B2"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      />
                    }
                    MenuProps={MenuProps}
                  >
                    {VisaStatusList.map((year) => (
                      <MenuItem key={year} value={year}>
                        <ListItemText primary={year} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Box>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" aria-label="close window" onClick={onClose}>
          Close
        </Button>

        <LoadingButton
          loadingPosition="center"
          aria-label="save custom card"
          variant="contained"
          loading={isSaving}
          onClick={onClickAdd}
          disabled={!isFormValid}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
