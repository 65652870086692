import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Stack,
  DialogTitle,
  IconButton,
  Slide,
  Fade,
  Button,
  DialogActions,
} from "@mui/material";
import React from "react";
import theme from "../../assets/theme/theme";
import HandIllus from "../../assets/images/illustrations/scanning-tips/hand.svg";
import SolidIllus from "../../assets/images/illustrations/scanning-tips/solid.svg";
import DottedIllus from "../../assets/images/illustrations/scanning-tips/dotted.svg";
import Step1Illus from "../../assets/images/illustrations/scanning-tips/step-1.svg";
import Step2Illus from "../../assets/images/illustrations/scanning-tips/step-2.svg";
import Step3Illus from "../../assets/images/illustrations/scanning-tips/step-3.svg";
import Step4Illus from "../../assets/images/illustrations/scanning-tips/step-4.svg";
import Step5Illus from "../../assets/images/illustrations/scanning-tips/step-5.svg";
import Step6Illus from "../../assets/images/illustrations/scanning-tips/step-6.svg";

import MSLens from "../../assets/images/illustrations/scanning-tips/ms-lens.svg";
import AdobeLens from "../../assets/images/illustrations/scanning-tips/adobe-lens.svg";
import { map } from "lodash";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DialogCloseIconBtn from "../DialogCloseIconBtn";

export interface Props {
  handleClose: () => any;
  open: boolean;
}

const steps = [
  Step1Illus,
  Step2Illus,
  Step3Illus,
  Step4Illus,
  Step5Illus,
  Step6Illus,
];
const stepTitles = [
  "Lay the document flat.",
  "Ensure there’s good lighting and no shadows.",
  "Ensure the image is sharp in focus.",
  "Crop the extra space around the edges.",
  "Preserve the default orientation.",
  "Save the scan in pdf, png or jpeg format.",
];

const ScanningTipsModal: React.FC<Props> = ({ open, handleClose }) => {
  const [stepNumber, setStepNumber] = React.useState(0);
  const containerRef = React.useRef(null);

  const handleNext = React.useCallback(() => {
    setStepNumber((prev) => {
      const last = steps.length - 1;
      const change = prev + 1;
      const set = change > last ? 0 : change;
      return set;
    });
  }, []);

  const handleBack = React.useCallback(() => {
    setStepNumber((prev) => {
      const last = 0;
      const change = prev - 1;
      const set = change < last ? steps.length - 1 : change;
      return set;
    });
  }, []);

  React.useEffect(() => {
    const code = window.setInterval(() => {
      if (!open) {
        setStepNumber(0);
      }
      if (open) {
        handleNext();
      }
    }, 5000);
    return () => window.clearInterval(code);
  }, [handleNext, open]);

  const onClose = React.useCallback(() => {
    setStepNumber(0);
    handleClose();
  }, [handleClose]);

  const renderStep = React.useCallback(
    (stepNumber: number, stepInView: number) => {
      if (stepInView !== stepNumber) return null;
      return (
        <Box key={stepNumber} className="scanning-tips-model">
          <Box
            className="scanning-tips-main"
            sx={
              {
                // background: 'green'
              }
            }
            ref={containerRef}
          >
            <Slide
            className="scanning-tips-section"
              // container={containerRef.current}
              in={true}
              direction="up"
              unmountOnExit
              mountOnEnter
              timeout={1000}
            >
              <img
                className="scanning-tips-number"
                style={{
                  width: "100%",
                  height: "380px",
                  padding: 40,
                  paddingBottom: 20,
                }}
                src={steps[stepNumber]}
                alt={`scanning tips number ${stepNumber + 1}`}
              />
            </Slide>
            <Slide
              // container={containerRef.current}
              in={true}
              direction="up"
              unmountOnExit
              mountOnEnter
              timeout={1000}
            >
              <Stack direction="row" gap={2} pl={"40px"} pr={"40px"} className="scanning-number-section">
                <Box display="flex">
                  <Typography
                    variant="h1"
                    color={theme.palette.primary.main}
                    fontSize={60}
                    fontWeight={400}
                  >
                    {stepNumber + 1}
                  </Typography>
                </Box>
                <Box display="flex" alignItems={"center"}>
                  <Typography variant="h4" fontSize={22} className="scanning-number-text">
                    {stepTitles[stepNumber]}
                  </Typography>
                </Box>
              </Stack>
            </Slide>
          </Box>
        </Box>
      );
    },
    []
  );

  if (!open) return null;

  return (
    <Dialog
      TransitionComponent={Fade}
      transitionDuration={1000}
      closeAfterTransition
      open={open}
      sx={{
        ".MuiPaper-root": {
          maxWidth: "calc(100% - 64px)",
          width: "75%",
        },
      }}
      className="scanning-tips-blog"
      scroll="body"
      onClose={onClose}
    >
      <DialogTitle className="document-text">
        Document{" "}
        <span style={{ color: theme.palette.primary.main, paddingLeft: "7px" }}>
          {" "}
          Scanning Tips
        </span>
        <DialogCloseIconBtn onClick={onClose} />
      </DialogTitle>

      <DialogContent className="scanning-blog-main"
        sx={{ background: theme.palette.primary.light, overflow: "hidden" }}
      >
        <Box
          className="scanning-blog-section"
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr auto",
          }}
        >
          <Box
            className="scanning-blog-contain"
            sx={{
              // background: "red",
              alignSelf: "center",
            }}
          >
            <Box
              className="scanning-blog-img"
              sx={{
                height: 190,
                width: "100%",
                // display: 'flex',
                // alignItems: 'center',
                background: theme.palette.primary.light,
                backgroundImage: `url(${HandIllus}), url(${SolidIllus}), url(${DottedIllus}) ;`,
                backgroundPosition: `center`,
                backgroundRepeat: "no-repeat",
              }}
            ></Box>
          </Box>
          <>
            {map(steps, (s, stepInView) => renderStep(stepNumber, stepInView))}
          </>
          <Box
            className="scanning-blog-dots"
            sx={{
              background: theme.palette.primary.light,
              alignSelf: "center",
            }}
          >
            <Stack direction="column" gap={1} className="scanning-blog-dots-contain">
              {/* icon buttons */}
              <IconButton onClick={handleBack}>
                <KeyboardArrowUpRoundedIcon />
              </IconButton>
              {map(steps, (s, i) => {
                const color =
                  stepNumber === i ? { color: theme.palette.primary.main } : {};
                return (
                  <IconButton
                    onClick={() => setStepNumber(i)}
                    key={`slider-indicator-${i}`}
                    sx={{
                      ...color,
                      padding: 0,
                      "&:hover": {
                        background: "none",
                        color: theme.palette.primary.main,
                      },
                      svg: {
                        width: "20px",
                        height: "20px",
                      },
                    }}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                  >
                    <FiberManualRecordIcon />
                  </IconButton>
                );
              })}
              <IconButton onClick={handleNext}>
                <KeyboardArrowDownRoundedIcon />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className="recomended-section">
        <Stack sx={{ background: "#FFFFFF" }} direction="row" gap={2} className="recomended-section-contain">
          <Typography
            fontSize={18}
            sx={{
              display: "flex",
              alignSelf: "center",
              mr: "20px",
            }}
          >
            Our Recommended Apps
          </Typography>
          <Box className="recomended-section-scan">
          <Button
            className="recomended-section-btn"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.microsoft.office.officelens&hl=en_IN&gl=US"
            startIcon={<img src={MSLens} alt="microsoft lens" />}
            component="a"
            sx={{
              border: `2px solid #B2B2B2`,
              color: `${theme.palette.text.primary}`,
              padding: "10px 22px",
            }}
          >
            Microsoft Lens
          </Button>
          <Button
            className="text-adobe recomended-section-btn"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.adobe.scan.android&hl=en_IN&gl=US"
            startIcon={<img src={AdobeLens} alt="Adobe Scan" />}
            component="a"
            sx={{
              border: `2px solid #B2B2B2`,
              color: `${theme.palette.text.primary}`,
              padding: "10px 22px",
            }}
          >
            Adobe Scan
          </Button>
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ScanningTipsModal;
