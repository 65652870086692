import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { rtdb } from "../firebase";
import {
  ICustomFolder,
  IDocument,
  IDocumentField,
  IUser,
} from "../firebaseTypes";
import { useAuth } from "./AuthContext";
import {
  debounce,
  findIndex,
  get,
  groupBy,
  isEmpty,
  map,
  reduce,
  sortBy,
} from "lodash";
import FolderPassportIcon from "../components/Shared/Icons/FolderPassportIcon";
import constants from "../constants";
import { onValue, ref, remove, update, get as getData } from "firebase/database";
import { downloadResource } from "../utils/fetchUtils";
import { Snackbar, Grow } from "@mui/material";
import {
  generateDocName,
  getDocName,
  getExpiryFieldValue,
  sortFoldersByPriority,
} from "../utils/documentUtils";
import { useUserContext } from "./UserContext";
import {
  isValidDateString,
  storageGetItem,
  storageSetItem,
} from "../utils/utils";
import theme from "../assets/theme/theme";
import { IGetDocumentsResponse } from "../middleTypes";
import DocumentMaybeWrongWarning from "../components/DocumentMaybeWrongWarning";

export interface IFolder {
  count: number;
  title: string;
  docType?: string;
  displayOnDashboard?: boolean; // others folder is part of displayOnDashboard folder, because we don't want to display others on dashboard
  custom?: boolean; // user created folders are part of custom folders
  icon: ReactNode;
  docs: IDocument[];
}

export function getKeyForIsFetchingThumbnail(docId: string): string {
  return `THUMB_${docId}-isFetchingThumbnailURL`;
}

export function getKeyForThumbnailUrl(docId: string): string {
  return `THUMB_${docId}-thumbnailURL`;
}

function clearSessionStorageWithPrefix(prefix: string) {
  const keys = [] as string[];
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    key && keys.push(key);
  }
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (key.startsWith(prefix)) {
      key && sessionStorage.removeItem(key);
    }
  }
}

// For decending sort
function compare(a: any, b: any) {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined && b !== undefined) {
    return 1;
  }
  if (a !== undefined && b === undefined) {
    return -1;
  }
  return b - a;
}

function sortDocuments(documents: IDocument[]): IDocument[] {
  let docsCopy = map(documents, (doc) => ({ ...doc }));
  docsCopy.sort((v1, v2) => {
    const expiryDateV1 = getExpiryFieldValue(v1.doc_info);
    const expiryDateV2 = getExpiryFieldValue(v2.doc_info);

    const x1 = isValidDateString(expiryDateV1)
      ? new Date(expiryDateV1).getTime()
      : 0;
    const x2 = isValidDateString(expiryDateV2)
      ? new Date(expiryDateV2).getTime()
      : 0;

    // const e1 = getExpirySeconds(v1);
    // const e2 = getExpirySeconds(v2);?
    return compare(x1, x2);
  });
  return docsCopy;
}

function documentsToFolders(
  documents: IDocument[],
  customFolders?: ICustomFolder[]
): IFolder[] {
  const groupedDocs = groupBy(documents, "doc_type");
  // Ideally nullFolder shouldn't be array, it holds a single value, keeping it array for simple array concatinations.
  let nullFolder: IFolder[] = [];
  // Create map for faster search
  const customFoldersMap: { [key: string]: boolean } =
    reduce(
      customFolders,
      (acc, cf) => {
        return {
          ...acc,
          [cf.name]: true,
        };
      },
      {}
    ) || {};

  // Automatic Folders including others
  const folders: IFolder[] = reduce(
    groupedDocs,
    (acc, docs, docType) => {
      if (customFoldersMap[docType]) {
        return acc;
      }

      if (docType === constants.docTypes.null) {
        // Do not add null folder to the list, add it to the end of sorted list later
        nullFolder = [
          {
            count: docs.length,
            title: docType,
            docType,
            displayOnDashboard: true,
            icon: <FolderPassportIcon />,
            docs: sortDocuments(docs),
          },
        ];
        return acc;
      }

      return [
        ...acc,
        {
          count: docs.length,

          title: docType,
          docType,
          icon: <FolderPassportIcon />,
          docs: sortDocuments(docs),
        },
      ];
    },
    [] as IFolder[]
  );

  // Custom Folders
  const cfs: IFolder[] = reduce(
    customFolders,
    (acc, cf) => {
      return [
        ...acc,
        {
          count: groupedDocs[cf.name] ? groupedDocs[cf.name].length : 0,
          title: cf.name,
          docType: cf.name,
          icon: <FolderPassportIcon />,
          docs: groupedDocs[cf.name] || [],
          custom: true,
        },
      ];
    },
    [] as IFolder[]
  );
  return [...sortBy(folders, "title"), ...nullFolder, ...sortBy(cfs, "title")];
}

export interface ITravelDocument {
  path: string;
  doc_format: string;
}

export interface IDocumentContext {
  documents: IDocument[];
  folders: IFolder[];
  initializedDocumentsAndFolders: boolean;
  // fetchDocumentsForUser: () => Promise<boolean>;
  downloadDocument: (doc: IDocument) => Promise<void>;
  downloadDocuments: (doc: IDocument[]) => Promise<void>;
  updateDocumentInTransaction: (
    doc: IDocument,
    getUpdates: (d: IDocument) => Partial<IDocument>,
    showSuccessSnackbar?: boolean
  ) => Promise<boolean>;
  deleteDocument: (doc: IDocument) => Promise<void>;
  deleteDocuments: (doc: IDocument[]) => Promise<void>;
  getDocDownloadUrl: (doc: IDocument) => string;
  getDocThumbnailUrl: (doc: IDocument) => any;
  cache: ICache;
}

const defaultValues: IDocumentContext = {
  documents: [],
  folders: [],
  initializedDocumentsAndFolders: false,
  // fetchDocumentsForUser: () => Promise.resolve(true),
  downloadDocument: async () => { },
  downloadDocuments: async () => { },
  updateDocumentInTransaction: async () => {
    return true;
  },
  deleteDocument: async () => { },
  deleteDocuments: async () => { },
  getDocDownloadUrl: () => "",
  getDocThumbnailUrl: () => "",
  cache: {},
};

const DocumentContext = createContext(defaultValues);

export function useDocumentContext() {
  return useContext(DocumentContext);
}

function removePrefix(str: string) {
  const regEx = /^documents\//;
  return (str || "").replace(regEx, "");
}

function sanitizeField(field: IDocumentField): IDocumentField {
  let manual_value = field.manual_value;
  let raw_value = field.raw_value;
  let value = field.value;
  // sanatize manual
  if (
    field.manual &&
    field.manual_value &&
    typeof field.manual_value === "object" &&
    field.manual_value.hasOwnProperty("_seconds")
  ) {
    // check if it is date && corrupted with value _seconds
    manual_value = {
      ...manual_value,
      seconds: field.manual_value._seconds,
    };
  }

  // sanatize raw_value
  if (
    field.raw_value &&
    typeof field.raw_value === "object" &&
    field.raw_value.hasOwnProperty("_seconds")
  ) {
    raw_value = {
      ...raw_value,
      seconds: field.raw_value._seconds,
    };
  }

  // sanatize value
  if (
    field.value &&
    typeof field.value === "object" &&
    field.value.hasOwnProperty("_seconds")
  ) {
    value = {
      ...value,
      seconds: field.value._seconds,
    };
  }

  return {
    ...field,
    manual_value,
    raw_value,
    value,
  };
}

function sanitizeDocument(d: IDocument, user: IUser): IDocument {
  return {
    ...d,
    doc_info: map(d.doc_info, (field) => sanitizeField(field)),
    doc_type: d.doc_type == null ? constants.docTypes.null : d.doc_type,
    doc_name: removePrefix(d.doc_name),
    doc_name_autogenerated: generateDocName(d, user as IUser),
  };
}

function sanitizeDocuments(docs: IDocument[], user: IUser): IDocument[] {
  return map(docs, (d) => sanitizeDocument(d, user));
}

export interface ICache {
  [key: string]: string;
}

export const DocumentProvider: React.FC = (props) => {
  const { currentUser } = useAuth();
  const { user } = useUserContext();
  const [documents, setDocuments] = useState(defaultValues.documents);
  const [folders, setFolders] = useState(defaultValues.folders);
  const [cache, setCache] = useState({});
  const [initializedDocumentsAndFolders, setinitializedDocumentsAndFolders] =
    useState(false);
  const [isFetchingDocuments, setIsFetchingDocuments] = useState(false);
  const [{ openSnackBar, message }, setOpenSnackBar] = React.useState({
    openSnackBar: false,
    message: "",
  });

  React.useEffect(() => {
    clearSessionStorageWithPrefix("THUMB_");
  }, []);

  React.useEffect(() => {
    const folders = documentsToFolders(documents, user?.customFolders);
    setFolders(sortFoldersByPriority(folders));
  }, [documents, user]);

  const handleSnackbarClose = useCallback(() => {
    setOpenSnackBar({
      openSnackBar: false,
      message: "",
    });
  }, []);

  const getDocThumbnailUrl = React.useCallback(
    async (document: IDocument) => {
      // return if doc url is already set
      const urlFromStorage =
        get(cache, getKeyForThumbnailUrl(document.id)) ||
        storageGetItem(getKeyForThumbnailUrl(document.id));

      if (!isEmpty(urlFromStorage)) {
        return urlFromStorage;
      }

      // return if fetching from another execution triggered via rtdb onValue
      const isFetching =
        get(cache, getKeyForIsFetchingThumbnail(document.id)) ||
        storageGetItem(getKeyForIsFetchingThumbnail(document.id));

      if (isFetching === "true") {
        return "";
      }

      try {
        if (currentUser) {
          // set isfetching TRUE
          setCache((prev) => ({
            ...prev,
            [getKeyForIsFetchingThumbnail(document.id)]: "true",
          }));
          storageSetItem(getKeyForIsFetchingThumbnail(document.id), "true");
          const url = `${constants.middleHost}/users/${currentUser.uid}/documents/${document.id}/assets/thumbnail`;
          const token = await currentUser.getIdToken();
          // fetch the image
          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          // Create an object URL from the data.
          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);
          setCache((prev) => ({
            ...prev,
            [getKeyForIsFetchingThumbnail(document.id)]: "false",
            [getKeyForThumbnailUrl(document.id)]: objectUrl,
          }));
          storageSetItem(getKeyForIsFetchingThumbnail(document.id), "false");
          storageSetItem(getKeyForThumbnailUrl(document.id), objectUrl);
          return objectUrl;
        }
      } catch (err) {
        setCache((prev) => ({
          ...prev,
          [getKeyForIsFetchingThumbnail(document.id)]: "false",
        }));
        storageSetItem(getKeyForIsFetchingThumbnail(document.id), "false");
        console.error(err);
      }
      return "";
    },
    [cache, currentUser]
  );

  const getDocDownloadUrl = React.useCallback((document: IDocument): string => {
    return `${constants.middleHost}/users/${document.uid}/documents/${document.id}/assets/document`;
  }, []);

  async function _downloadDocument(doc: IDocument) {
    // 1. gets downloadURL
    // 2. downloads pdf via fetch and create temp anchor and simulates click. See downloadResource for more info.
    if (currentUser) {
      const token = await currentUser?.getIdToken();
      const downloadURL = getDocDownloadUrl(doc);
      await downloadResource(downloadURL, getDocName(doc), {
        Authorization: `Bearer ${token}`,
      });
    }
  }
  const downloadDocument = useCallback(_downloadDocument, [
    getDocDownloadUrl,
    currentUser,
  ]);

  async function _downloadDocuments(docs: IDocument[]) {
    // 1. gets downloadURL
    // 2. downloads pdf via fetch and create temp anchor and simulates click. See downloadResource for more info.
    if (currentUser) {
      const token = await currentUser?.getIdToken();

      setOpenSnackBar({
        openSnackBar: true,
        message: `Downloading in progress, Please don't refresh the page...`,
      });
      const promises = map(docs, async (doc) => {
        const downloadURL = getDocDownloadUrl(doc);
        await downloadResource(downloadURL, getDocName(doc), {
          Authorization: `Bearer ${token}`,
        });
      });
      await Promise.all(promises);
      setOpenSnackBar({
        openSnackBar: true,
        message: `Successfully downloaded ${documents.length} documents!`,
      });
    }
  }
  const downloadDocuments = useCallback(_downloadDocuments, [
    getDocDownloadUrl,
    documents.length,
    currentUser,
  ]);

  const _updateDocumentInTransaction = async (
    document: IDocument,
    getUpdates: (document: IDocument) => Partial<IDocument>,
    showSuccessSnackbar = true
  ): Promise<boolean> => {

    if (currentUser && currentUser.uid && !isEmpty(document.id)) {
      try {
        console.log("update start");
        const docRef = ref(
          rtdb,
          `users/${currentUser.uid}/documents/${document.id}`
        );
        const { doc_name_autogenerated, ...updates } = document;

        await update(docRef, getUpdates(updates));

        // update local react state
        setDocuments((docs) => {
          const index = findIndex(docs, ({ id }) => id === document.id);
          if (index > -1 && index < docs.length) {
            const newDoc = { ...docs[index], ...updates };
            const newDocs = [...docs];
            newDocs[index] = newDoc;
            return newDocs;
          }
          return docs;
        });
        if (showSuccessSnackbar) {
          setOpenSnackBar({
            openSnackBar: true,
            message: `Successfully saved documents!`,
          });
        }
        console.log("update end");
      } catch (err) {
        console.error(err);
        setOpenSnackBar({
          openSnackBar: true,
          message: `Error while updating document! Please retry.`,
        });
        return false;
      } finally {
      }
    }
    return true;
  };
  const updateDocumentInTransaction = useCallback(
    _updateDocumentInTransaction,
    [currentUser]
  );

  async function _deleteDocument(document: IDocument) {
    if (document && document.id) {
      try {
        const rtdbRef = ref(
          rtdb,
          `/users/${currentUser?.uid}/documents/${document.id}`
        );
        await remove(rtdbRef);
        // // update local react state
        // setDocuments((docs) => {
        //   return filter(docs, (d) => d.id === document.id);
        // });
        console.log("undo: removed:", rtdbRef.key);
      } catch (err) {
        console.error(err);
      } finally {
      }
    }
  }
  const deleteDocument = useCallback(_deleteDocument, [currentUser?.uid]);

  async function _deleteDocuments(documents: IDocument[]) {
    if (documents && documents.length > 0) {
      try {
        setOpenSnackBar({
          openSnackBar: true,
          message: `Deleting in progress, Please don't refresh the page...`,
        });
        // remove from rtdb
        const promises = map(documents, (document) => {
          if (document.id) {
            const rtdbRef = ref(
              rtdb,
              `/users/${currentUser?.uid}/documents/${document.id}`
            );
            return remove(rtdbRef);
          }
        });
        await Promise.all(promises);
        // // update local react state
        // setDocuments((docs) => {
        //   const deletedDocIds = map(documents, ({ id }) => id);
        //   return filter(
        //     docs,
        //     (d) => findIndex(deletedDocIds, (id) => id === d.id) > -1
        //   );
        // });
        setOpenSnackBar({
          openSnackBar: true,
          message: `Successfully deleted ${documents.length} documents!`,
        });
      } catch (err) {
        console.error(err);
      } finally {
      }
    }
  }
  const deleteDocuments = useCallback(_deleteDocuments, [currentUser?.uid]);

  const onValueCallback = useCallback(async () => {
    if (!currentUser?.uid) return;
    if (!user?.id) return;
    if (isFetchingDocuments) return;
    try {
      console.log("onValue start");
      setIsFetchingDocuments(true);
      const url = `${constants.middleHost}/users/${currentUser.uid}/documents?page=1&itemsPerPage=100`;
      const token = await currentUser.getIdToken();
      // fetch the image
      const res: Response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });
      const { data }: IGetDocumentsResponse = await res.json();
      const docs = sanitizeDocuments(data, user as IUser);
      // map(docs, (doc) => {
      //   getDocThumbnailUrl(doc);
      // });
      console.log(`undo: docs`, docs);
      setDocuments(docs);
      setinitializedDocumentsAndFolders(true);
      setIsFetchingDocuments(false);
      console.log("onValue end");
    } catch (error) {
      console.error(error);
    }
  }, [currentUser, isFetchingDocuments, user]);

  // const debouncedOnValue = useCallback(
  //   ,
  //   [currentUser]
  // );

  const debouncedOnValue = debounce(onValueCallback, 500, {
    leading: false,
  });

  const getConstantsFromDb = useCallback(async () => {
    if (!currentUser?.uid) return;
    const folders: string[] = Object.values((await getData(ref(rtdb, `constants/folders`))).val());
    const visaStatuses: string[] = Object.values((await getData(ref(rtdb, `constants/VisaStatusList`))).val());
    const priorityOrder: string[] = Object.values((await getData(ref(rtdb, `constants/priorityOrder`))).val());
    // update constants
    constants.folders = folders;
    constants.VisaStatusList = visaStatuses;
    constants.priorityOrder = priorityOrder;
  }, [currentUser?.uid]);

  const init = useCallback(async () => {
    // Register onValue
    if (!currentUser?.uid) return;
    if (!user?.id) return;
    await getConstantsFromDb();
    const documentsRef = ref(rtdb, `users/${currentUser.uid}/documents`);
    // important: onValueCallback has to be throttled because we don't want to add load to fetch documents api in middle server
    // allow one call in one second
    onValueCallback();
    const unsubscribe = onValue(documentsRef, debouncedOnValue);
    return unsubscribe;
  }
    , [currentUser?.uid, user?.id, getConstantsFromDb]);

  useEffect(() => {
    init();
  }, [init]);


  return (
    <DocumentContext.Provider
      value={{
        documents,
        folders,
        initializedDocumentsAndFolders,
        downloadDocument,
        downloadDocuments,
        updateDocumentInTransaction,
        deleteDocuments,
        deleteDocument,
        getDocDownloadUrl,
        getDocThumbnailUrl,
        cache,
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openSnackBar}
        sx={{
          ".MuiSnackbarContent-root": {
            background: theme.palette.primary.main,
          },
        }}
        TransitionComponent={Grow}
        message={message}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
      />
      {props.children}
    </DocumentContext.Provider>
  );
};
