import { FC, useMemo, useState } from "react";
import theme, { DCPageSummary } from "../../assets/theme/theme";
import TravelHistoryIllustration from "../../assets/images/illustrations/travel-history.svg";
import { Typography, Box, Tooltip, Divider } from "@mui/material";
import { differenceInDays, format, isBefore } from "date-fns";
import { findFlagCodeByCountryName } from "../../utils/countryUtils";
import { DCFlag } from "../Shared/DCFlag";
import { KeyValueItem } from "../Shared/KeyValueItem";
import DisplayLockUnlock from "../DashboardMain/DisplayLockUnlock";
import ReportOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircleIcon from "@mui/icons-material/Circle";
import { useDocumentContext } from "../../contexts/DocumentContext";
import { IDocument } from "../../firebaseTypes";
import { toDateTime } from "../../utils/dateTimeUtils";
import {
  getFieldDisplayValue,
  getFieldValue,
  isExpired,
} from "../../utils/documentUtils";
import { SensitiveDataMask } from "../Shared/SensitiveDataMask";
import { useTravelHistoryContextNew } from "./TravelHistoryContextNew";

function ExpireOrValidIcon({
  isDateExpired,
  expireDate,
}: {
  expireDate: string | null;
  isDateExpired: boolean;
}) {
  return isDateExpired ? (
    <Tooltip title={`Expired on ${expireDate}`} arrow placement="top">
      <ReportOutlinedIcon
        sx={{ marginLeft: 1 }}
        color="error"
        fontSize="small"
      />
    </Tooltip>
  ) : (
    <Tooltip title={`Valid until ${expireDate}`} arrow placement="top">
      <CheckCircleOutlineIcon
        sx={{ marginLeft: 1 }}
        color="success"
        fontSize="small"
      />
    </Tooltip>
  );
}

type CountryDurationCode = {
  country: string;
  duration: number;
  countryCode: string;
  active: boolean;
};
export const TravelSummaryNew: FC = () => {
  const { documents } = useDocumentContext();
  const { usaTravelRecords, worldTravelRecords } = useTravelHistoryContextNew();
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [usaCountry, setUsaCountry] = useState<CountryDurationCode | null>(
    null
  );

  const I94Doc = useMemo<{
    i94Number: string;
    i94Date: string;
    i94Expired: boolean;
    doc: IDocument;
  } | null>(() => {
    const doc = documents.find((d) => d.doc_type === "I-94");
    if (doc && doc.doc_info) {
      const i94NumberData = doc.doc_info.find((i) => i.title === "I-94 Number");
      const i94DateData = doc.doc_info.find(
        (i) => i.title === "Admit Until Date"
      );
      const i94Number = getFieldDisplayValue(i94NumberData) || "";
      const i94Date = getFieldDisplayValue(i94DateData);

      return {
        i94Number: i94Number,
        i94Date,
        i94Expired: isExpired(i94DateData),
        doc,
      };
    }
    return null;
  }, [documents]);

  const countryDurations = useMemo<CountryDurationCode[]>(() => {
    const mergedList = [
      ...(usaTravelRecords || []),
      ...(worldTravelRecords || []),
    ];
    const presentCountry = mergedList.find((c) => c.isPresent);
    setUsaCountry(null);

    const countryCodeList = mergedList
      .reduce<CountryDurationCode[]>((arr, travel) => {
        const duration = travel.isPresent
          ? differenceInDays(new Date(), travel.arrival_date_parsed!)
          : differenceInDays(
              travel.departure_date_parsed!,
              travel.arrival_date_parsed!
            ) + 1;

        const existData = arr.find((a) => a.country === travel.country);
        if (existData && duration) {
          existData.duration += duration;
        } else {
          arr.push({
            country: travel.country,
            duration: duration || 0,
            active: presentCountry?.country === travel.country,
            countryCode: findFlagCodeByCountryName(travel.country),
          });
        }

        return arr;
      }, [])
      .sort((a, b) => {
        return b.duration - a.duration;
      });

    const _usaCountryIndex = countryCodeList.findIndex(
      (c) => c.country === "USA"
    );

    if (_usaCountryIndex > -1) {
      setUsaCountry(countryCodeList[_usaCountryIndex]);
      countryCodeList.splice(_usaCountryIndex, 1);
    }

    return countryCodeList;
  }, [worldTravelRecords, usaTravelRecords]);

  const getCountryElement = (country: CountryDurationCode) => {
    return (
      <Typography
        key={country.countryCode}
        pr={1}
        noWrap
        fontSize="18px"
        color={theme.palette.text.secondary}
      >
        <DCFlag
          countryCode={country.countryCode}
          style={{ width: "20px", marginRight: "10px" }}
        />
        {country.country} :{" "}
        <Typography component="span" fontSize="18px" color="black">
          {country.duration} days
        </Typography>
        {country.active && (
          <Tooltip title="Currently staying in the country">
            <Typography
              component="span"
              color={theme.palette.primary.main}
              sx={{ cursor: "pointer", ml: 1 }}
            >
              (Active)
            </Typography>
          </Tooltip>
        )}
      </Typography>
    );
  };

  return (
    <Box sx={{ mt: -16, position: "relative" }} className="travel-log-main">
      <DCPageSummary className="travel-log-inner-section">
        <Box sx={{ display: "flex" }} className="trvel-summary-main">
          <Box>
            <Box
              className="travel-summary-section"
              sx={{
                background: theme.palette.background.default,
                height: "100%",
                display: "flex",
                mr: "20px",
                width: "calc(100% - 20px)",
                borderRadius: "10px",
                alignItems: "center",
                justifyContent: "end",
                color: theme.palette.primary.main,
                p: 2,
                pr: 4,
              }}
            >
              <Box
                component="img"
                sx={{
                  "@media screen and (max-width: 1366px)": {
                    display: "none",
                  },
                }}
                alt="Travel history illustration"
                src={TravelHistoryIllustration}
                width={160}
                mt={-7}
                mr={4}
              ></Box>
              <Typography
                fontSize={20}
                lineHeight={1.4}
                fontWeight={500}
                component="h2"
                zIndex={1}
              >
                Travel
                <br />
                Summary
              </Typography>
            </Box>
          </Box>
          {worldTravelRecords?.length !== 0 || usaCountry || I94Doc ? (
            <Box
              sx={{ display: "flex", flexWrap: "wrap" }}
              className="travel-summary-content"
            >
              {(usaCountry || I94Doc) && (
                <Box
                  className="travel-summary-details"
                  sx={{
                    "@media (max-width:600px)": {
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    },
                  }}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  rowGap={1}
                  px={3}
                >
                  {usaCountry && getCountryElement(usaCountry)}
                  {I94Doc ? (
                    <>
                      <KeyValueItem
                        label="I-94 Number:"
                        textToCopy={I94Doc.i94Number}
                        marginBottom={0}
                        icon={
                          <DisplayLockUnlock
                            isInfoVisible={isInfoVisible}
                            setIsInfoVisible={setIsInfoVisible}
                            fieldLabel="I-94 Number"
                          />
                        }
                      >
                        {!isInfoVisible ? (
                          <SensitiveDataMask
                            sensitiveValue={I94Doc.i94Number}
                          />
                        ) : (
                          I94Doc.i94Number
                        )}
                      </KeyValueItem>
                      <KeyValueItem
                        label="Admit Until Date:"
                        textToCopy={I94Doc.i94Date}
                        marginBottom={0}
                        icon={
                          <ExpireOrValidIcon
                            expireDate={I94Doc.i94Date}
                            isDateExpired={I94Doc.i94Expired}
                          />
                        }
                      >
                        {I94Doc.i94Date}
                      </KeyValueItem>
                    </>
                  ) : (
                    <>
                      <KeyValueItem
                        label="I-94 Number:"
                        marginBottom={0}
                      ></KeyValueItem>
                      <KeyValueItem
                        label="Admit Until Date:"
                        marginBottom={0}
                      ></KeyValueItem>
                    </>
                  )}
                </Box>
              )}
              <Divider orientation="vertical" />
              <Box
                display="flex"
                alignItems="center"
                columnGap={3}
                px={1}
                className="travel-summary-country-count"
              >
                {countryDurations.slice(0, 3).map((c) => getCountryElement(c))}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "large" }}
                color={theme.palette.text.secondary}
              >
                No Records! Record your travels now to see travel summary.
              </Typography>
            </Box>
          )}
        </Box>
      </DCPageSummary>
    </Box>
  );
};
