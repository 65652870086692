import React from "react";
import { IconButton, Typography, Box, Link, ListItemButton, ListItemText } from "@mui/material";
import theme from "../../assets/theme/theme";
import TimerSharp from "@mui/icons-material/TimerSharp";
import { INotification } from "../../contexts/NotificationsContext";
import CloseIcon from '@mui/icons-material/Close';
import { useNotifications } from "../../contexts/NotificationsContext";
import Moment from "react-moment";
import { INotificationsEnum } from "../../firebaseTypes";

interface Props {
  note: INotification;
  onNotificationClick: (note: INotification) => any;
}
const NotificationDocExpiry: React.FC<Props> = ({ note, onNotificationClick }) => {
  const { archiveNotification } = useNotifications();
  return (
    <ListItemButton
      sx={{
        p: 2,
        mb : -0.1,
        bgcolor: note.seen === true ? "white" : theme.palette.primary.light,
        "&:hover": {
          bgcolor: note.seen === true ? theme.palette.grey[100] : "#d9e5ff" ,
        }
      }}
    >
      <TimerSharp
        sx={{
          color: theme.palette.text.secondary,
          marginRight: "10px",
          marginTop: "-1px",
          fontSize: "1.5rem"
        }}
      />
      {note.type === INotificationsEnum["doc-expiry"] && (
      <ListItemText
        primary={
          <>
            <Typography
              component="span"
              variant={"body1"}
              minWidth={166}
              color={theme.palette.text.primary}
              onClick={() => onNotificationClick(note)}
            >
              Your <span style={{ fontWeight: "bold" }}>{note.doc_type}</span> is about to expire on <b>{note.metadata?.formattedExpiryDateStr}</b>
            </Typography>
            {note.metadata?.helpLink && (<Box><Box
              component={Link}
              target="_blank"
              href={note.metadata?.helpLink}
              sx={{
                textDecoration: "none",
                fontSize: "0.8rem",
                fontWeight: 600,
              }}
            >
              {note.metadata?.helpText}
            </Box>
            </Box>
            )}
          </>
        }
        secondary={<Moment fromNow>{note.timeStamp}</Moment>}
      >  </ListItemText>
      )}
      <IconButton
        onClick={() => archiveNotification(note)} sx={{
          color: theme.palette.text.secondary,
          marginLeft: 3,
        }}>
        <CloseIcon />
      </IconButton>
    </ListItemButton>
  );
};

export default NotificationDocExpiry;
