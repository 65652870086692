import * as React from "react";
import Logo from "../Shared/Logo";
import AppBar from "@mui/material/AppBar";
import { Box, Paper, Typography } from "@mui/material";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import constants from "../../constants";
export interface Props {
  pageId: string;
}

const AppBarLayout: React.FC<Props> = (props) => {
  return (
    <Paper
      sx={{
        minHeight: "calc(100vh - 65px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar
        position="static"
        color="transparent"
        variant="outlined"
        sx={{
          boxShadow: "unset",
          display: "flex",
          justifyContent: "center",
          height: "80px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ float: "left", marginLeft: "48px" }}>
            <Logo height={33} />
          </Box>

          {props.pageId === constants.pages.sharedDocuments && (
            <Box
              sx={{
                float: "right",
                marginRight: "60px",
                marginTop: "10px",
                alignItems: "top",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                style={{ color: "#757575", fontWeight: 500 }}
              >
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <VerifiedUserOutlinedIcon sx={{ fontSize: "22px" }} />
                  &nbsp;Securely Shared By Immplify
                </Box>
              </Typography>
            </Box>
          )}
        </Box>
      </AppBar>
      <Box
        id="page-wrap"
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "flex",
          },
          justifyContent: "center",
        }}
      >
        {props.children}
      </Box>
    </Paper>
  );
};
export default AppBarLayout;
