import {
  Menu,
  Tooltip,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDocumentContext } from "../../contexts/DocumentContext";
import { IDocument } from "../../firebaseTypes";
import DeleteIcon from "../Shared/Icons/DeleteIcon";
import DownloadIcon from "../Shared/Icons/DownloadDocumentIcon";
import ShareIcon from "../Shared/Icons/ShareIcon";
// import SendIcon from '@mui/icons-material/Send';
import theme, { DcDocumentIconButton } from "../../assets/theme/theme";
import { useDocumentShareModalContext } from "../../contexts/DocumentShareModalContext";
import {
  MoveToFolderModalProvider,
  useMoveToFolderModalContext,
} from "../../contexts/MoveToFolderModalContext";
import { useDocumentDeleteModal } from "../../contexts/DocumentDeleteModalContext";
import MoveToFolderIcon from "../Shared/Icons/MoveToFolderIcon";
import MoreIcon from "../Shared/Icons/MoreIcon";

export interface Props {
  document: IDocument;
  hideDelete?: boolean;
  showMoveTo?: boolean;
}

function MoveToMenuItem(props: any) {
  const { openModal } = useMoveToFolderModalContext();
  const onMoveToDocument = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <MenuItem onClick={onMoveToDocument} className=" document-options-blog">
      <ListItemIcon style={{ minWidth: "unset" }}>
        <MoveToFolderIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Move to another folder</ListItemText>
    </MenuItem>
  );
}

const DocumentOptionsMenu: React.FC<Props> = ({
  document,
  hideDelete,
  showMoveTo,
}) => {
  const [disableDownloadBtn, setDisableDownloadBtn] = useState(false);
  const { openShareModalFor } = useDocumentShareModalContext();
  const { downloadDocument } = useDocumentContext();
  const { openDeleteModalFor } = useDocumentDeleteModal();
  const [anchorEl, setAnchorEl] = React.useState(null as HTMLElement | null);
  const open = Boolean(anchorEl);

  const _handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose = useCallback(_handleClose, []);

  const onShareButtonClick = useCallback(() => {
    openShareModalFor([document]);
    handleClose();
  }, [document, openShareModalFor, handleClose]);

  const onDownloadDocument = useCallback(async () => {
    setDisableDownloadBtn(true);
    await downloadDocument(document);
    setDisableDownloadBtn(false);
    handleClose();
  }, [document, downloadDocument, handleClose]);

  const onDeleteDocument = useCallback(async () => {
    openDeleteModalFor([document]);
    handleClose();
  }, [document, openDeleteModalFor, handleClose]);

  const _handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = useCallback(_handleClick, []);

  return (
    <>
      <Tooltip title="More Options" arrow placement="top">
        <DcDocumentIconButton
          aria-label="options"
          id={`document-options-${document.id}`}
          aria-controls="document-options"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreIcon fontSize="small" />
        </DcDocumentIconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        MenuListProps={{
          "aria-labelledby": "document-options",
        }}
      >
        <MoveToFolderModalProvider document={document}>
          <MoveToMenuItem fontSize="small" />
        </MoveToFolderModalProvider>

        <MenuItem disabled={disableDownloadBtn} onClick={onDownloadDocument}>
          <ListItemIcon style={{ minWidth: "unset" }}>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download document</ListItemText>
        </MenuItem>

        <MenuItem onClick={onShareButtonClick}>
          <ListItemIcon style={{ minWidth: "unset" }}>
            <ShareIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Share document</ListItemText>
        </MenuItem>

        {!hideDelete && (
          <MenuItem onClick={onDeleteDocument}>
            <ListItemIcon
              style={{ minWidth: "unset", color: theme.palette.error.main }}
            >
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ color: theme.palette.error.main }}>
              Delete document
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default DocumentOptionsMenu;
