import { Box } from "@mui/system";
import React from "react";
import Header from "./Header";

import { styled } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import FooterNotice from "./FooterNotice";
import constants from "../../constants";
import { useDocumentContext } from "../../contexts/DocumentContext";
import {
  DropzoneProvider,
  useDropzoneContext,
} from "../../contexts/DropzoneContext";
import { DocumentModalProvider } from "../../contexts/DocumentModalContext";
import {
  SidebarProvider,
  useSidebarContext,
} from "../../contexts/SidebarContext";
import { useUserContext } from "../../contexts/UserContext";
import { CustomCardProvider } from "../../contexts/CreateCustomCardContext";
import CloudUpload from "../../assets/images/illustrations/cloud_upload.svg";
import { Typography, useMediaQuery } from "@mui/material";
import theme from "../../assets/theme/theme";
import { CustomCardModalProvider } from "../../contexts/CustomCardModalContext";

export interface Props {
  pageId: string;
}

const drawerWidth = 240;

const DCMain = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "matchUpMd",
})<{
  open?: boolean;
  matchUpMd: boolean;
}>(({ theme, open, matchUpMd }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  minHeight: "calc(100vh - 65px)",
  marginLeft: matchUpMd ? `-${drawerWidth}px` : 0,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    width: "100%",
  }),
  width: "calc(100% - 240px)",
}));

const MainWrapper: React.FC<Props> = (props) => {
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const { open } = useSidebarContext();
  return (
    <DCMain open={open} matchUpMd={matchUpMd}>
      {props.children}
    </DCMain>
  );
};

const DashboardLayout: React.FC<Props> = (props) => {
  const { initializedDocumentsAndFolders: initalizedDocumentsAndFolders, documents } = useDocumentContext();

  // if (!initalizedDocumentsAndFolders) {
  //   /* show spinner while documents is loading */
  //   return <Spinner />;
  // } else if (initializedUser && isEmpty(user)) {
  //   return <Redirect to='/profile/setup' />
  // } else if (initalizedDocumentsAndFolders && documents.length === 0 && props.pageId !== constants.pages.viewUploadDocs) {
  //   /* if no documents found, then redirect to /documents, only on dashboard  */
  //   return <Redirect to='/documents' />
  // }

  return (
    // dashboard page and view/upload documents
    initalizedDocumentsAndFolders && documents.length >= 0 ? (
      // undo-todo: remove DocumentModalCustomCardProvider
      // <DocumentModalCustomCardProvider>
      <CustomCardModalProvider>
        <DocumentModalProvider>
          <CustomCardProvider>
            <SidebarProvider>
              <DropzoneProvider>
                <Box sx={{ minHeight: "100%", display: "flex" }}>
                  <Header pageId={props.pageId} />
                  <Sidebar pageId={props.pageId} />

                  <MainWrapper pageId={props.pageId}>
                    <DropzoneArea pageId={props.pageId}>
                      <Box height={"100%"}>{props.children}</Box>
                    </DropzoneArea>
                  </MainWrapper>
                </Box>
              </DropzoneProvider>
            </SidebarProvider>
          </CustomCardProvider>
        </DocumentModalProvider>
      </CustomCardModalProvider>
    ) : // </DocumentModalCustomCardProvider >
    null
  );
};
export default DashboardLayout;

const DropzoneArea: React.FC<Props> = (props) => {
  const { initializedDocumentsAndFolders: initalizedDocumentsAndFolders, documents } = useDocumentContext();
  const { isDragActive, getRootProps } = useDropzoneContext();
  const { user } = useUserContext();

  function showFolders() {
    // if user has custom folders always showFolder irrespective of if they have documents or not
    if (user?.customFolders && user.customFolders.length > 0) {
      return true;
    }
    return initalizedDocumentsAndFolders && documents.length > 0;
  }

  return (
    <Box
      sx={{
        paddingTop: "64px",
        // minHeight: "calc(100% - 64px)",
        height: "100%",
      }}
    >
      {/* <Sidebar pageId={props.pageId} /> */}
      {isDragActive ? (
        <Box
          sx={{
            width: "100%",
            height: "calc(100% - 64px)",
            boxShadow: `0 10px 150px -86px   ${theme.palette.primary.main} inset`,
            transition:
              "box-shadow .1s ease-in-out, background .1s ease-in-out",
            background: "rgba(255,80,0,.08) !important",
          }}
          display="flex"
          justifyContent="center"
          flex={1}
          {...getRootProps()}
        >
          <Box>
            <Box textAlign="center" mt={12.5} ml={-1.5}>
              <img
                src={CloudUpload as any}
                alt="upload icon"
                className="bounce-up"
              />
            </Box>
            <Box my={4} textAlign="center">
              <Typography variant="h3" component="h2">
                Drop files anywhere to upload
              </Typography>
              <Typography
                component="p"
                mt={4}
                color={theme.palette.text.secondary}
              >
                Maximum Document Size should be <strong>20 MB</strong>. <br />
                Only <strong>PDF, PNG or JPEG</strong> Formats are acceptable.
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          {...getRootProps()}
          // position='inherit'
          width="100%"
          // minHeight="calc(100% - 64px)"
          height="100%"
          // sx={
          //   props.applyStyles ? bugyStyles : undefined
          // }
        >
          {props.children}
        </Box>
      )}
      {props.pageId === constants.pages.viewUploadDocs && (
        <FooterNotice show={showFolders()} />
      )}
    </Box>
  );
};
