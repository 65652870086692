import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import CustomCardModal from "../components/CustomCardModal";
import { firestore, rtdb } from "../firebase";
import { ICustomDocument, IDocumentField } from "../firebaseTypes";
import { useAuth } from "./AuthContext";
import { push, ref, remove, serverTimestamp, update } from "firebase/database";
import { useDocumentContext } from "./DocumentContext";
import { get, map } from "lodash";
import { format } from "date-fns";
import { collection, addDoc } from "firebase/firestore";
import { PDFMergerProvider } from "./PDFMergerContext";

type ICustomDocumentEditable = ICustomDocument;

export interface ICustomCardModalContext {
  downloadURL: string;
  customDocumentEditable?: ICustomDocumentEditable;
  setCustomDocumentEditable: React.Dispatch<
    React.SetStateAction<ICustomDocumentEditable>
  >;
  openModalForEdit: (customDocument: ICustomDocument) => void;
  openModalForNewCard: (docInfo: any, template: string) => void;
  handleDeleteCustomCard: (id: string) => void;
  setCustomCardField: (index: number, changes: Partial<IDocumentField>) => void;
  handleSave: () => void;
  setDownloadURL: React.Dispatch<React.SetStateAction<string>>;
  handleAttachDocument: (
    attached_document_id: string,
    doc_name: string,
    doc_format: string
  ) => void;
  handleAttachDocumentCard: (
    card_id: string,
    attached_document_id: string,
    doc_name: string,
    doc_format: string
  ) => Promise<void>;
  detachDocument: () => void;
  detachDocumentCard: (card_id: string) => void;
}

const defaultValues: ICustomCardModalContext = {
  downloadURL: "",
  setCustomDocumentEditable: () => {},
  setCustomCardField: () => {},
  openModalForEdit: () => {},
  openModalForNewCard: () => {},
  handleDeleteCustomCard: () => {},
  handleAttachDocument: () => {},
  handleSave: () => {},
  setDownloadURL: () => {},
  handleAttachDocumentCard: async () => {},
  detachDocument: async () => {},
  detachDocumentCard: async (card_id: string) => {},
};

const CustomCardModalContext = createContext(defaultValues);

export function useCustomCardModalContext() {
  return useContext(CustomCardModalContext);
}

type IMode = "create" | "edit";

function getEmptyCustomDoc(
  uid: string,
  docInfo: IDocumentField[] | undefined,
  template: "default"
): ICustomDocument {
  return {
    doc_info: docInfo,
    template,
    doc_name: "",
    doc_type: "custom",
    doc_title: "",
    uid,
  };
}

//Adding the tasks
async function createDailyTask(
  trackingDate: Date,
  duration: string,
  custom_doc_path: string,
  expiry_date: Date
) {
  try {
    const trackingDateFormatted = format(trackingDate, "MM-dd-yyyy"); //Formatting the dates
    const dailyTaskRef = collection(
      firestore,
      `tasks/${trackingDateFormatted}/daily-tasks`
    );
    const taskRef = await addDoc(dailyTaskRef, {
      custom_doc_path,
      expiry_date,
      expiry_duration: duration,
      function_url: "URL",
    });
    return {
      task_id: taskRef.id,
      task_path: `tasks/${trackingDateFormatted}/daily-tasks`,
    };
  } catch (error) {
    console.error(error);
  }
}

export const CustomCardModalProvider: React.FC = (props) => {
  const { currentUser } = useAuth();
  const [openCloseState, setOpenCloseState] = useState(true);
  const [downloadURL, setDownloadURL] = useState(defaultValues.downloadURL);
  const [customDocumentEditable, setCustomDocumentEditable] = useState(
    defaultValues.customDocumentEditable
  );
  const [mode, setMode] = useState<IMode>("create");
  const { getDocDownloadUrl } = useDocumentContext();

  // set download url based on doc_path changes
  useEffect(() => {
    if (customDocumentEditable?.attached_document_id && currentUser?.uid) {
      const url = getDocDownloadUrl({
        uid: currentUser.uid,
        id: customDocumentEditable.attached_document_id,
      } as any);
      setDownloadURL(url);
    }
  }, [
    currentUser?.uid,
    customDocumentEditable?.id,
    customDocumentEditable?.attached_document_id,
    getDocDownloadUrl,
    openCloseState
  ]);

  const onModalClose = useCallback((reason?: string) => {
    // reason could be "backdropClick" | "escapeKeyDown"
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setDownloadURL(defaultValues.downloadURL);
    setOpenCloseState(false);
  }, []);

  const openModalForEdit = useCallback((customDocument) => {
    setMode("edit");

    setCustomDocumentEditable(customDocument);
    setOpenCloseState(true);
  }, []);

  const openModalForNewCard = useCallback(
    (docInfo, template) => {
      if (currentUser?.uid) {
        setMode("create");
        setDownloadURL(defaultValues.downloadURL);
        setCustomDocumentEditable(
          getEmptyCustomDoc(currentUser?.uid, docInfo, template)
        );
        setOpenCloseState(true);
      }
    },
    [currentUser?.uid]
  );

  const detachDocumentCard = React.useCallback(
    async (card_id: string) => {
      const cardRef = ref(
        rtdb,
        "users/" + currentUser?.uid + "/customDocuments/" + card_id
      );
      await update(cardRef, {
        attached_document_id: null,
        doc_name: null,
        doc_format: null,
      });
      console.debug("Card Updated");
    },
    [currentUser?.uid]
  );

  const detachDocument = useCallback(() => {
    setCustomDocumentEditable((prev) => {
      if (prev) {
        return {
          ...prev,
          attached_document_id: null,
          doc_name: null,
          doc_format: null,
        } as any;
      }
    });
  }, []);

  const handleDeleteCustomCard = React.useCallback(
    async (id: string) => {
      try {
        const cardRef = ref(
          rtdb,
          "users/" + currentUser?.uid + "/customDocuments/" + id
        );
        await remove(cardRef);
        setOpenCloseState(false);
      } catch (error) {
        console.error(error);
      }
    },
    [currentUser?.uid]
  );

  const handleSave = React.useCallback(async () => {
    async function saveCustomDocumentWithTasks(id: string) {
      try {
        if (id) {
          const customDocPath =
            "users/" + currentUser?.uid + "/customDocuments/" + id;
          const newDocInfo: IDocumentField[] = [];
          let newTaskRef = customDocumentEditable?.taskRef || [];

          for (const field of customDocumentEditable?.doc_info || []) {
            if (field.date && field.trackingDates) {
              let newTrackingDates: any = {};

              for (const [key, checkbox] of Object.entries(
                field.trackingDates
              )) {
                if (
                  checkbox.checked &&
                  checkbox.taskRef === undefined &&
                  get(checkbox, ["value", 0]) &&
                  get(checkbox, ["value", 1])
                ) {
                  // create task
                  const trackingDateTuple = checkbox.value;
                  const taskRef = await createDailyTask(
                    new Date(trackingDateTuple[0] as Date),
                    trackingDateTuple[1] as string,
                    customDocPath,
                    field.value as Date
                  );
                  // append taskRef in new objects
                  if (taskRef) {
                    newTrackingDates[key] = { ...checkbox, taskRef };
                    newTaskRef.push(taskRef);
                  }
                } else {
                  // push entry as it is
                  newTrackingDates[key] = { ...checkbox };
                }
              }

              newDocInfo.push({
                ...field,
                trackingDates: newTrackingDates,
              });
            } else {
              newDocInfo.push(field);
            }
          }

          // update custom document in rtdb
          const cardRef = ref(rtdb, customDocPath);
          await update(cardRef, {
            ...JSON.parse(JSON.stringify(customDocumentEditable)),
            doc_info: newDocInfo,
            task_ref: newTaskRef,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (customDocumentEditable?.id) {
      await saveCustomDocumentWithTasks(customDocumentEditable?.id);
    } else {
      try {
        if (currentUser?.uid) {
          const customDocumetsRef = ref(
            rtdb,
            "users/" + currentUser?.uid + "/customDocuments"
          );
          const newCustomCardRef = await push(customDocumetsRef, {
            created_time: serverTimestamp(),
            ...customDocumentEditable,
          });
          newCustomCardRef.key &&
            (await saveCustomDocumentWithTasks(newCustomCardRef.key));
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [currentUser?.uid, customDocumentEditable]);

  const handleAttachDocument = useCallback(
    (attached_document_id: string, doc_name: string, doc_format: string) => {
      setCustomDocumentEditable((prev) => {
        if (prev) {
          return {
            ...prev,
            attached_document_id,
            doc_name,
            doc_format,
          };
        }
      });
    },
    []
  );

  const handleAttachDocumentCard = React.useCallback(
    async (
      card_id: string,
      attached_document_id: string,
      doc_name: string,
      doc_format: string
    ) => {
      const cardRef = ref(
        rtdb,
        "users/" + currentUser?.uid + "/customDocuments/" + card_id
      );
      await update(cardRef, {
        attached_document_id,
        doc_name,
        doc_format,
      });
      console.debug("Card Updated");
    },
    [currentUser?.uid]
  );

  const setCustomCardField = useCallback(
    (index: number, changes: Partial<IDocumentField>) => {
      setCustomDocumentEditable((prev) => {
        if (!prev) return prev;
        const oldField = get(prev, ["doc_info", index]);
        const newField = {
          ...oldField,
          ...changes,
        };
        const newDocInfo = map(prev.doc_info, (f, i) => {
          if (i === index) return newField;
          return f;
        });

        return {
          ...prev,
          doc_info: newDocInfo,
        };
      });
    },
    []
  );

  return (
    <CustomCardModalContext.Provider
      value={{
        downloadURL,
        setDownloadURL,
        customDocumentEditable,
        setCustomDocumentEditable: setCustomDocumentEditable as any,
        openModalForEdit,
        openModalForNewCard,
        handleDeleteCustomCard,
        handleAttachDocument,
        setCustomCardField,
        handleSave,
        handleAttachDocumentCard,
        detachDocument,
        detachDocumentCard,
      }}
    >
      <PDFMergerProvider>
        <CustomCardModal
          openCloseState={openCloseState}
          onModalClose={onModalClose}
          mode={mode}
        />
      </PDFMergerProvider>
      {props.children}
    </CustomCardModalContext.Provider>
  );
};
