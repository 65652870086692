import { AuthErrorCodes } from "@firebase/auth";
import {
  Alert,
  Button,
  Container,
  Grid,
  Hidden,
  Stack,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/system";
import theme from "../../assets/theme/theme";
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Illustration from "../../assets/images/illustrations/auth_illustration.svg";
import { FormWrapper } from "../../assets/theme/theme";
import { useAuth } from "../../contexts/AuthContext";
import Logo from "../Shared/Logo";
/* eslint-disable @typescript-eslint/no-unused-vars */
export interface Props { }
const LogIn: React.FC<Props> = (props) => {
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const { login, currentUser, signUpWithGoogle, signUpWithApple } = useAuth();
  const history = useHistory();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  function checkLogInDisabled() {
    return loading || !emailInput || !passwordInput;
  }

  async function handleSubmit(e: any) {
    e.preventDefault();

    try {
      if (emailInput && passwordInput) {
        setError("");
        setLoading(true);
        await login(emailInput, passwordInput);
        history.push("/");
      }
    } catch (error: any) {
      // A full list of error codes is available at AuthErrorCodes on @firebase/auth
      switch (error.code) {
        case AuthErrorCodes.USER_DELETED:
          setError(`${error.code}: Please sign up to create user account.`);
          break;
        default:
          setError("Failed to log in.");
      }
    }

    setLoading(false);
  }

  if (currentUser) {
    return <Redirect to="/" />;
  }

  const redirectPrivacy = () => {
    const win = window.open("/privacy-policy", "_blank");
    win?.focus();
  };

  const redirectTerms = () => {
    const win = window.open("/terms-and-condition", "_blank");
    win?.focus();
  };

  return (
    <Container maxWidth={false} disableGutters className="full-height">
      <Grid container spacing={0} className="full-height">
        <Hidden>
          <Grid item xs={12} sm={6} bgcolor={theme.palette.background.default}
            sx={{
              '@media (max-width:600px)': {
                display: 'none;'
              },
            }}>
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              className="full-height"
            >
              <Box width="80%" m="auto"
                sx={{
                  flexWrap: 'wrap',
                  '@media (max-width:600px)': {
                    display: 'none;'
                  },
                }}>
                <img src={Illustration} alt="Happy" width="100%" />
              </Box>
            </Box>
          </Grid>
        </Hidden>
        <Grid item sm={6} flex={1}>
          <FormWrapper>
            <Box width="50%" mx="auto"
              sx={{
                '@media (max-width:768px)': {
                  width: '80%',
                },

              }}>
              {/* <Grid container spacing={0} justifyContent='space-between'>
                <Grid item>
                  <Typography variant='h3' component='h1' fontWeight={500}>
                    Sign In
                  </Typography>
                </Grid>
                <Grid item textAlign='right'>
                  New user?
                  <Link to='/signup' className='text-decoration-none'>
                    <Typography component='span' color='#4B84FF' ml={1}>
                      Create an account
                    </Typography>
                  </Link>
                </Grid>
              </Grid> */}
              <Stack direction="column" alignItems="center" spacing={6}>
                <Box mb={"14px"}>
                  <Logo height={35} />
                </Box>
                <Typography variant="h3" fontWeight={500}>
                  Welcome back!
                </Typography>

                <>
                  <Box my={4} width="100%">
                    {!isChecked ? (
                      <Box sx={{display: "flex", flexDirection: "column", gap: 1.5}}>
                        <Button
                          fullWidth
                          className=" google-sign-up dc-button--tertiary"
                          startIcon={<img src="/google-colored.svg" alt="Google" />}
                          size="large"
                          disabled={true}
                        >
                          Sign in with Google
                        </Button>
                        <Button
                          fullWidth
                          className=" apple-sign-up dc-button--tertiary"
                          startIcon={<img src="/apple-logo.svg" alt="Apple" />}
                          size="large"
                          disabled={true}
                        >
                          Sign in with Apple
                        </Button>
                      </Box>
                    ) : (
                      <Box sx={{display: "flex", flexDirection: "column", gap: 1.5}}>
                        <Button
                          className="google-sign-up"
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<img src="/google.svg" alt="Google" />}
                          onClick={() => signUpWithGoogle()}
                          disabled={!isChecked}
                          disableElevation
                        >
                          Sign in with Google
                        </Button>
                        <Button
                          className="apple-sign-up"
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<img src="/apple-logo-white.svg" alt="Apple" />}
                          onClick={() => signUpWithApple()}
                          disabled={!isChecked}
                          disableElevation
                        >
                          Sign in with Apple
                        </Button>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 2
                      }}
                    >
                      <Checkbox
                        value={isChecked}
                        onClick={() => setIsChecked(!isChecked)}
                      />
                      <Typography>
                        Accept{" "}
                        <span
                          style={{
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                          onClick={redirectPrivacy}
                        >
                          privacy policy
                        </span>{" "}
                        and{" "}
                        <span
                          style={{
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                          onClick={redirectTerms}
                        >
                          terms and condition
                        </span>
                        .
                      </Typography>
                    </Box>
                  </Box>
                </>
              </Stack>

              {/* <Divider sx={{ fontSize: 13 }}>or continue with</Divider> */}
              {error && (
                <Box mt={4} mb={2}>
                  <Alert variant="outlined" severity="error">
                    {error}
                  </Alert>
                </Box>
              )}
              {/* <Stack direction='column' spacing={4} mt={4}>
                <FormControl
                  variant='standard'
                  sx={{ width: '100%' }}
                  error={!!error}
                >
                  <InputLabel shrink htmlFor='bootstrap-input'>
                    Email
                  </InputLabel>
                  <DcInput
                    placeholder='Email'
                    value={emailInput}
                    onChange={(evt) => setEmailInput(evt.target.value)}
                    fullWidth
                  />
                </FormControl>
                <FormControl
                  variant='standard'
                  sx={{ width: '100%' }}
                  error={!!error}
                >
                  <InputLabel shrink htmlFor='bootstrap-input'>
                    Password
                  </InputLabel>
                  <DcInput
                    placeholder='Password'
                    type='password'
                    value={passwordInput}
                    onChange={(evt) => setPasswordInput(evt.target.value)}
                    fullWidth
                  />
                </FormControl>
              </Stack> */}
              {/* <Box mt={4} mb={2}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  size='large'
                  disabled={checkLogInDisabled()}
                  onClick={handleSubmit}
                >
                  SIGN IN
                </Button>
              </Box> */}
              {/* <Grid
                container
                spacing={0}
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckboxUncheckedIcon />}
                        checkedIcon={<CheckboxCheckedIcon />}
                      />
                    }
                    label='Remember me'
                  />
                </Grid>
                <Grid item textAlign='right'>
                  <Link to='/' className='text-decoration-none'>
                    <Typography
                      component='span'
                      color='#4B84FF'
                      ml={1}
                      fontSize={14}
                    >
                      Forgot Password?
                    </Typography>
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </FormWrapper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LogIn;
